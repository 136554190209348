import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { createSelector } from 'reselect';
import { calculateDataUserPayload, calculateReouccringPayload, _checkoutAddressFormat, _paymentPayload, _priceTypeForCalculate } from "../Methods/checkoutPayloads";
import { loopFunction, USERDATA_PAYLOAD, _scriptFunctionCall } from "../Methods/commonPayload";
import localStorageCall from "../Methods/localstorage.hook";
import { _setTimeOut } from "../Methods/normalMethods";
import { _formDefaultFormat } from "../Methods/validationSchema";
import { CalculateApi, CalculateAutoshipApi, CreateAutoshipOrderApi, CreateOrderApi } from "../Redux/Reducer/CheckoutSlice";
import { getCustomerSavedCard } from "../Redux/Reducer/CustomerSlice";
import { AuthenticateProfileCode, createMerchantProfile, ValidateCustomerProfile } from "../Redux/Reducer/LoginSlice";
import { _getPaymentHtml, _getPaymentOptions } from "../Redux/Reducer/PaymentSlice";

const withCheckoutHOC = (Component) => {

    const WithCheckoutHOComponent = (props) => {

        const { comingFrom, addToCartProducts, setAddToCartProduct } = props;
        const { dispatch, userData, navigate, ROUTING_TEXT, AllProductAndCheckoutText, autoshipOrders } = useOutletContext();
        let Dates = _.pick(userData, ['distributorDate', 'signUpDate', 'entryDate']);
        const isUserLogin = localStorageCall().getSingleItem('Token');
        const memorizeSelector = createSelector(
            (state) => state.PaymentSlice,
            (state) => state.CustomerSlice,
            (state) => state?.CheckoutSlice,
            (data, customerData, checkoutData) => {
                const { paymentOptions, paymentFormHtml, iframeLoader } = data;
                const { error, errorAddressData, normalAddressError, couponErrorData, shippingLoading, isLoading } = checkoutData
                return ({
                    couponErrorData,
                    paymentOptions, paymentFormHtml, iframeLoader,
                    savedCards: customerData?.savedCards,
                    error, errorAddressData, normalAddressError, isLoading, shippingLoading
                })
            }
        );

        const {
            paymentOptions, paymentFormHtml, savedCards, iframeLoader, error, couponErrorData,
            errorAddressData, normalAddressError, isLoading, shippingLoading
        } = useSelector((state) => memorizeSelector(state));

        const [formData, setFormData] = useState(USERDATA_PAYLOAD);

        const [userDetails, setUserDetails] = useState(null);

        const [otp, setOtp] = useState(new Array(6).fill(""));
        const [countryState, setCountryState] = useState({
            country: "", countryError: "",
            state: "", stateError: "",
        });
        const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: false });

        const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
        const [selectedPayment, setSelectedPayment] = useState({
            cardValue: null,
            selectedPaymentDropdown: null,
            selectedOptionForPayment: 2
        });

        const [calculatedData, setCalculatedData] = useState({
            autoshipCalculateData: null,
            oneTimeCalculateData: null
        });

        const [shippingMethods, setShippingMethods] = useState({
            autoshipShipMethod: [],
            oneTimeShipMethod: []
        });
        const [selectedShippingOption, setSelectedShippingOption] = useState({
            section: comingFrom,
            autoshipSelectedShipping: { shipCarrierId: null, shipMethodType: null },
            oneTimeSelectedShipping: { shipCarrierId: null, shipMethodType: null }
        });

        const [onSelectedCardError, setOnSelectedCardError] = useState('');
        const [checkValidUser, setCheckValidUser] = useState(false);
        const [otpPopUp, setOtpPopUp] = useState(false);
        const [storeToken, setStoreToken] = useState({ token: null, error: "" });

        const [usePoint, setUsePoint] = useState({
            wantToUSePoint: false,
            paymentObject: { currencyCode: "USD", maxAmount: '', pointAccountType: 1, PaymentType: 2 }
        });
        const [couponCode, setCouponCode] = useState({ code: "", valid: false });

        const [askPopupDlocal, setAskPopupDlocal] = useState(false);

        let calculteDataUser = calculateDataUserPayload(formData, selectedShippingOption, useDefaultAddressOrNot);
        let calculateAutoshipData = calculateReouccringPayload(formData, selectedShippingOption, useDefaultAddressOrNot);

        const _productPayload = (data, forWhat = "oneTime") => _.compact(_.map(data, (item, index) => {
            if (item?.id) {
                return ({
                    parentItemId: 0,
                    orderLineNumber: index + 1,
                    itemId: item?.id,
                    itemCode: item?.itemCode,
                    quantity: item?.quantity,
                    ...(forWhat !== "oneTime") ? { "parentLineNumber": 0 } : {}
                })
            }
        }));

        // validate user
        function _createMarchentProfile(data, callback) {
            const token = localStorageCall().getSingleItem('Token');
            dispatch(createMerchantProfile(token, data, (row) => {
                if (row === null) {
                    setStoreToken({ token: null, error: row?.errorMessage });
                } else {
                    if (row === "VALID") {
                        setCheckValidUser(true);
                        _setTimeOut(() => callback(), 3000)
                    } else {
                        setOtpPopUp(true);
                        setStoreToken({ token: row, error: "" });
                    }
                }
            }))
        };

        function _authenticateOtp(callback, flag = false) {
            const token = localStorageCall().getSingleItem('Token');
            if (flag) {
                setCheckValidUser(true);
                setOtpPopUp(false);
                _setTimeOut(() => callback(), 3000);
            } else {
                if (otp?.join('') === '121212') {
                    dispatch(AuthenticateProfileCode(token, storeToken?.token, otp?.join(''), (data) => {
                        if (data) _authenticateOtp(callback, true);
                    }))
                } else {
                    _authenticateOtp(callback, true);
                }
            }
        }

        const handleChangeValidate = (element, index) => {
            if (isNaN(element.value)) return false;
            setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        };

        /******************************************************************************************************************/

        // coupon functionality
        function _onCouponClick(action) {
            if (couponCode?.code) {
                calculteDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                _calculateApiCall('coupon');
            }
        }

        //checkout main calculation functionality
        function _handleCommonLoader(data, frequency = "oneTime") {
            if (data) {
                setCalculatedData((prv) => ({ ...prv, [`${frequency}CalculateData`]: data?.crmOrder }));

                let CHECK = !_.some(_.map(data?.shipMethods, 'shipMethodDescription'), (r) => r === null);
                setShippingMethods((prv) => ({ ...prv, [`${frequency}ShipMethod`]: CHECK ? data?.shipMethods : [] }));
                setSelectedShippingOption((prv) => ({
                    ...prv, section: comingFrom,
                    [`${frequency}SelectedShipping`]: {
                        shipCarrierId: CHECK ? data?.crmOrder?.shipCarrierId : null,
                        shipMethodType: CHECK ? data?.crmOrder?.shipMethodType : null
                    }
                }));
            }
        }
        // calling function for calculate api
        function _calculateApiCall(section) {
            const FILTER_PRODUCTS = addToCartProducts[comingFrom + 'Products'];
            const detailsArray = _productPayload(FILTER_PRODUCTS);
            const autoshipProduct = _productPayload(_.filter(FILTER_PRODUCTS, ({ frequency }) => (frequency === "autoship")));

            if (autoshipProduct?.length > 0) {
                setSavedThisCard((prv) => ({ ...prv, readOnly: true }))
            }

            if (detailsArray?.length > 0) {                                           /*************** normal order */
                calculteDataUser["details"] = detailsArray;
                calculteDataUser['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders)
                dispatch(CalculateApi(calculteDataUser, (data) => {
                    if (section === 'coupon') {
                        setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) ? true : false });
                    }
                    _handleCommonLoader(data);
                }))
            }
            if (autoshipProduct?.length > 0) {                                        /************** autoship order */
                calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
                calculateAutoshipData['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders)
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
        }

        // On field onBlur call function
        function calculateNormalOrder(event, values) {
            const { country, state } = countryState;
            let USER_DETAILS = {};
            if (event) {
                const { name, value } = event.target;
                USER_DETAILS = { ...values, shipping_country: country, shipping_state: state, [name]: value };
                setFormData(USER_DETAILS);
            } else {
                USER_DETAILS = values;
            }
            calculteDataUser = calculateDataUserPayload(USER_DETAILS, selectedShippingOption, useDefaultAddressOrNot)
            calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedShippingOption, useDefaultAddressOrNot);
            if ((USER_DETAILS?.shipping_street && USER_DETAILS?.shipping_country && USER_DETAILS?.shipping_state && USER_DETAILS?.shipping_city && USER_DETAILS?.shipping_zipcode)) {
                _calculateApiCall();
            }
        }

        // shipping change function
        function _handleShippingChange(data, section = "Today's order") {
            const FREQUENCY = (section === "Today's order") ? 'oneTime' : 'autoship';

            if (FREQUENCY) {
                const SELECTED_SHIPPING = { shipCarrierId: data?.shipCarrierId, shipMethodType: data?.shipMethodType };
                setSelectedShippingOption((prv) => ({ ...prv, [`${FREQUENCY}SelectedShipping`]: SELECTED_SHIPPING }));
                if (FREQUENCY === 'oneTime') {
                    calculteDataUser['shipMethod'] = _.assign(calculteDataUser?.shipMethod, SELECTED_SHIPPING);
                    calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, selectedShippingOption?.autoshipSelectedShipping);

                } else {
                    calculteDataUser['shipMethod'] = _.assign(calculteDataUser?.shipMethod, selectedShippingOption?.oneTimeSelectedShipping);
                    calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, SELECTED_SHIPPING);
                }
                _calculateApiCall();
            }
        }

        function _setUseDefaultAddressFunction(userData, useDefaultAddressOrNot, formData) {

            setCountryState((prv) => ({
                country: useDefaultAddressOrNot?.country || formData?.shipping_country || prv?.country || userData?.countryCode,
                state: useDefaultAddressOrNot?.state_region || formData?.shipping_state || prv?.state || userData?.shippingAddress?.regionProvState
            }));
            const CHANGE_COUNTRY = formData?.shipping_country ? { country: formData?.shipping_country, state: formData?.shipping_state } : countryState;
            const SHIPPING_DETAIL = _checkoutAddressFormat(userData, formData, useDefaultAddressOrNot, CHANGE_COUNTRY);
            setUserDetails(_formDefaultFormat(userData));
            setFormData(SHIPPING_DETAIL);

            calculteDataUser = calculateDataUserPayload(SHIPPING_DETAIL, selectedShippingOption, useDefaultAddressOrNot)
            calculateAutoshipData = calculateReouccringPayload(SHIPPING_DETAIL, selectedShippingOption, useDefaultAddressOrNot,);
            _calculateApiCall();

            dispatch(getCustomerSavedCard(isUserLogin, (values) => {
                if (values?.length > 0 && selectedPayment?.cardValue === null) {
                    setSelectedPayment((prv) => ({ ...prv, cardValue: values[0], selectedOptionForPayment: 1 }));
                }
            }));

        }

        function _updateDataOfUser(useDefaultAddressOrNot = null) {
            if (userData?.emailAddress) {
                if (comingFrom !== 'normal') {
                    _setUseDefaultAddressFunction(userData, useDefaultAddressOrNot, formData)
                }
            }
        }

        useEffect(() => {
            if (userData?.emailAddress) {
                dispatch(ValidateCustomerProfile(isUserLogin, (data) => {
                    if (data === null) {
                        setCheckValidUser(false);
                    } else {
                        if (data === "VALID") {
                            setCheckValidUser(true);
                        } else {
                            setCheckValidUser(false);
                        }
                    }
                }));

                dispatch(_getPaymentOptions((data) => {
                    if (data[0]?.paymentOptionId) {
                        setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: data[0]?.paymentOptionId }));
                        dispatch(_getPaymentHtml(data[0]?.paymentOptionId));
                    }
                }));
                _updateDataOfUser();
            }
        }, [userData?.emailAddress]);

        // **************** payment section **********************
        const [paymentOption_ID, setPaymentOption_ID] = useState(null);

        const [sameAsBillingChecked, setSameAsBillingChecked] = useState(false);

        function _paymentOptionSetBilling(address) {
            console.log(address);
            try {
                window.paymentOption?.setBilling({
                    street: address?.shipping_street,
                    city: address?.shipping_city,
                    region: address?.shipping_state,
                    postal_code: address?.shipping_zipcode,
                    country: address?.shipping_country
                });
            } catch (e) {
                console.log(e);
            }

        }

        function _handleDlocalPopup(action = 'close') {
            if (action === 'confirm') {
                const autoshipProduct = _.filter(addToCartProducts[comingFrom + 'Products'], ({ frequency }) => (frequency === "autoship"));
                const PRODUCTS = _.uniqBy([
                    ..._.reject(addToCartProducts[comingFrom + 'Products'], { frequency: "autoship" }),
                    ..._.map(autoshipProduct, (r) => ({ ...r, frequency: 'oneTime' }))], 'id')
                localStorageCall().setItem(String(comingFrom + 'Products'), JSON.stringify(PRODUCTS));
                setAddToCartProduct((prv) => ({ ...prv, [comingFrom + 'Products']: localStorageCall().getItem(String(comingFrom + 'Products')) }))
                calculateAutoshipData['details'] = [];  /** autoship order */
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
                setSelectedPayment((prv) => ({ ...prv, cardValue: null, selectedPaymentDropdown: paymentOption_ID?.paymentOptionId }));
                dispatch(_getPaymentHtml(paymentOption_ID?.paymentOptionId));
            } else {
                dispatch(_getPaymentHtml(selectedPayment?.selectedPaymentDropdown));
            }
            setAskPopupDlocal(false);
        }

        function _handleCardChange(data, section) {
            const DATA_OF_PAYMENT_OPTION = _.find(paymentOptions, { paymentOptionId: data });

            if (section === "savedCard") {
                setSelectedPayment((prv) => ({ ...prv, cardValue: data, selectedPaymentDropdown: null }));
            } else {
                if (data) {
                    const autoshipProduct = _.filter(addToCartProducts[comingFrom + 'Products'], ({ frequency }) => (frequency === "autoship"));
                    setPaymentOption_ID(DATA_OF_PAYMENT_OPTION);

                    if (!DATA_OF_PAYMENT_OPTION?.recurringSupported && autoshipProduct?.length > 0) {
                        setAskPopupDlocal(true);
                    } else {
                        setSelectedPayment((prv) => ({ ...prv, cardValue: null, selectedPaymentDropdown: data }));
                        dispatch(_getPaymentHtml(data));
                    }
                }
            }

        }

        function callBackAutoshipFunction(AutoshipProduct, values, orderid, sucess = false, callBack) {
            if (AutoshipProduct?.length > 0) {
                try {
                    let autoshipCartPayload = _paymentPayload(null, values, 'autoship', selectedPayment);
                    var date = new Date();
                    date.setDate(date.getDate() + (!sucess ? 30 : 60))
                    calculateAutoshipData['startDate'] = moment(date).utc().format();
                    calculateAutoshipData['frequencyType'] = !sucess ? 1 : 7;
                    calculateAutoshipData["details"] = AutoshipProduct;
                    calculateAutoshipData['priceType'] = _priceTypeForCalculate(comingFrom, AutoshipProduct, autoshipOrders)

                    if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                        autoshipCartPayload['token'] = selectedPayment?.cardValue?.token;
                        autoshipCartPayload['customerAccountId'] = selectedPayment?.cardValue?.customerAccountId;
                        autoshipCartPayload['recurringPaymentActionType'] = 2;
                        calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": +calculatedData?.autoshipCalculateData?.orderTotal }];
                        dispatch(CreateAutoshipOrderApi(calculateAutoshipData, comingFrom, orderid, callBack));

                    } else {
                        _scriptFunctionCall(paymentFormHtml, () => {
                            loopFunction(paymentFormHtml, ({ tokenId }) => {
                                if (tokenId) {
                                    autoshipCartPayload["token"] = tokenId;
                                    autoshipCartPayload['recurringPaymentActionType'] = 1;
                                    calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": +calculatedData?.autoshipCalculateData?.orderTotal, }];
                                    dispatch(CreateAutoshipOrderApi(calculateAutoshipData, comingFrom, orderid, callBack));
                                }
                            })
                        }, autoshipCartPayload);
                    }
                } catch (e) {
                    console.log(e)
                }

            }
        }

        const _onHandleSubmit = async (values, simplecheckoutPayload = null, preAuthOrderId = 0) => {
            const FILTER_PRODUCTS = addToCartProducts[comingFrom + 'Products'];
            const detailsArray = _productPayload(FILTER_PRODUCTS);
            const autoshipProduct = _productPayload(_.filter(FILTER_PRODUCTS, ({ frequency }) => (frequency === "autoship")));
            let checkoutPayload = _paymentPayload(null, values, 'normal', selectedPayment);
            calculteDataUser["details"] = detailsArray?.length > 0 && detailsArray;
            calculteDataUser['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders);
            calculteDataUser['payments'] = [{ ...checkoutPayload, "maxAmount": +calculatedData?.oneTimeCalculateData?.orderTotal }];
            calculteDataUser['preAuthOrderId'] = +preAuthOrderId;

            calculteDataUser['couponCodes'] = paymentOption_ID?.paymentOptionTypeDescription === 'Redirect' && couponCode?.code ? [couponCode.code.trim()] : [];

            if (simplecheckoutPayload?.token || preAuthOrderId) {
                if (detailsArray?.length > 0) {
                    const POINT_DISCOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                    const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;
                    calculteDataUser['payments'] = [{
                        ...simplecheckoutPayload,
                        "maxAmount": +calculatedData?.oneTimeCalculateData?.orderTotal - POINT_AMOUNT,
                    }, ...POINT_DISCOUNT];
                    dispatch(CreateOrderApi(calculteDataUser, autoshipProduct, (orderid) => {
                        callBackAutoshipFunction(autoshipProduct, values, orderid)
                    }, { ...userDetails, ...Dates }, comingFrom))

                } else {
                    callBackAutoshipFunction(autoshipProduct, values, null)
                }
            } else {
                if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                    checkoutPayload = {
                        ...checkoutPayload,
                        saveToken: false,
                        token: selectedPayment?.cardValue?.token,
                        customerAccountId: selectedPayment?.cardValue?.customerAccountId
                    }
                    _onHandleSubmit(values, checkoutPayload);
                } else {
                    if (selectedPayment?.selectedOptionForPayment === 1) {
                        setOnSelectedCardError('Please select your payment method.');
                        _setTimeOut(() => setOnSelectedCardError(''), 3000);
                    } else {
                        _scriptFunctionCall(paymentFormHtml, () => {
                            loopFunction(paymentFormHtml, ({ tokenId, preAuthOrderId }) => {
                                checkoutPayload["token"] = tokenId;
                                checkoutPayload['saveToken'] = (autoshipProduct?.length > 0) ? savedThisCard?.checked : savedThisCard?.checked ? savedThisCard?.checked : false;
                                _onHandleSubmit(values, checkoutPayload, preAuthOrderId);
                            })

                        }, calculteDataUser);

                    }
                }
            }
        };

        return <Component {...props} {...{
            isUserLogin, error,
            formData, setFormData, selectedPayment, setSelectedPayment, savedCards, savedThisCard, setSavedThisCard, askPopupDlocal,
            usePoint, setUsePoint, onSelectedCardError, setOnSelectedCardError, otp, setOtp, otpPopUp, setOtpPopUp,
            countryState, setCountryState, shippingMethods, selectedShippingOption, calculatedData, paymentOptions, paymentFormHtml,
            userDetails, setUserDetails, checkValidUser, navigate, ROUTING_TEXT, isLoading, AllProductAndCheckoutText,
            useDefaultAddressOrNot, setUseDefaultAddressOrNot, userData, errorAddressData, couponCode, setCouponCode, couponErrorData,
            paymentOption_ID, iframeLoader, sameAsBillingChecked, setSameAsBillingChecked, shippingLoading,

            _handleShippingChange, handleChangeFunction: calculateNormalOrder, handleChangeValidate, _createMarchentProfile, _paymentOptionSetBilling,
            _productPayload, dispatch, _authenticateOtp, _onHandleSubmit, _handleCardChange, _onCouponClick, _handleDlocalPopup, _setUseDefaultAddressFunction,
        }} />
    }

    return WithCheckoutHOComponent;
}

export default withCheckoutHOC;