import _ from 'lodash';
import TextFieldComponent from "../../../Inputs/TextFieldComponent";
import { ButtonCssClass, storeAssigned } from "../../../services/Methods/normalMethods";
import ButtonComponent from "../../../Inputs/ButtonComponent";
import CountryAndStateInputs from "../../../Inputs/CountryAndState.Input";
import { RegisterFormsPropsType } from '../../../services/Methods/PropsTypesInterface';
import { IconButton, Typography } from '@mui/material';
import { RegisterFormStyle } from '../../../../styles/RegisterFormStyle';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';
import { Check, Close } from '@mui/icons-material';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { useEffect } from 'react';

const RegisterForms = (props) => {
    const REFFERAL_VALUES = localStorageCall().getItem('refferal_link');
    const {
        isUserLogin,
        emailExistError,
        fieldValue,
        countryState,
        setCountryState,
        _validateRefferalFunction,
        webAliesError,
        setWebAliesErros,
        countryCodes,
        buttonText,
        Title,
        isWebAlias,
        isRefferal,
        activeStep
    } = props;


    const InputField = (name, label, type, disable) => {
        return (
            <div className='md-3-field'>
                <TextFieldComponent
                    name={name}
                    type={type ? type : 'text'}
                    label={label}
                    value={fieldValue?.values?.[name]}
                    error={!!(fieldValue?.errors?.[name])}
                    onChange={fieldValue?.handleChange}
                    errorMessage={fieldValue?.errors?.[name]}
                    disabled={disable}
                />
                {name === "phone" ? <span className="f-q-regular">Note: phone number will be used to validate a code in the next step *</span> : ""}
            </div>
        )
    };
    useEffect(() => {

        if (REFFERAL_VALUES && REFFERAL_VALUES.webalies) {
            fieldValue.setFieldValue('refferal', REFFERAL_VALUES.webalies, true);
        }

    }, [activeStep]);
    return (
        <RegisterFormStyle>
            {Title && <Typography className='form-heading'>{Title}</Typography>}
            {!isUserLogin && <div className='text-right mb-5'>Already have an account? <a className='text-button-color hover:text-button-hover-color transition-all ease-in-out duration-300' href={`/${storeAssigned()}/${ROUTING_TEXT?.login_routingName}`}>Login</a></div>}
            <div className='row-form -mx-[10px] flex flex-wrap'>
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('firstName', "First Name")}
                </div>
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('lastName', "Last Name")}
                </div>
                <div className="w-full px-[10px] mb-5">
                    <TextFieldComponent
                        name="email"
                        type='text'
                        label="E-mail"
                        value={fieldValue?.values?.email}
                        error={!!(fieldValue?.errors?.email || emailExistError)}
                        onChange={fieldValue?.handleChange}
                        errorMessage={fieldValue?.errors?.email || emailExistError}
                        disabled={!!isUserLogin}
                    />
                </div>
                <CountryAndStateInputs {...{ setCountryState, countryCodes, countryState }} />
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('address', "Address")}
                </div>
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('address_2', "Address 2 (optional) ")}
                </div>
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('city', "City")}
                </div>
                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('zipcode', "Zip Code")}
                </div>
                {isRefferal &&
                    <div className="md:w-1/2 w-full px-[10px] mb-5">
                        {InputField('company_name', "Company Name (Optional ) ")}
                    </div>
                }

                <div className="w-1/2 px-[10px] flex gap-[20px] mb-5">
                    <TextFieldComponent
                        name="refferal"
                        type='text'
                        className="w-full"
                        disabled={!!(REFFERAL_VALUES?.webalies && !webAliesError?.changeRefferal)}
                        label={"Referral"}
                        placeholder={"Referral"}
                        value={fieldValue?.values?.refferal}
                        onChange={fieldValue?.handleChange}
                        error={(!!webAliesError?.isRefferalError) || !!fieldValue?.errors.refferal}
                        errorMessage={webAliesError?.isRefferalError || fieldValue?.errors.refferal}
                    />
                    {(REFFERAL_VALUES?.webalies && !webAliesError?.changeRefferal)
                        ?
                        <button type="button" className={`${ButtonCssClass} leading-[1px]`}
                            onClick={() => setWebAliesErros((prv) => ({ ...prv, changeRefferal: true }))}>
                            Change
                        </button>
                        :
                        <div className='flex gap-[5px] h-full max-h-[40px] mt-[6px]'>
                            <IconButton type='button' className='!bg-button-color !text-[#fff]' onClick={() => _validateRefferalFunction(fieldValue?.values?.refferal)}><Check /></IconButton>
                            <IconButton type='button' className='!bg-button-color !text-[#fff]' onClick={() => {
                                setWebAliesErros((prv) => ({ ...prv, changeRefferal: false }));
                                fieldValue.setFieldValue('refferal', REFFERAL_VALUES?.webalies, true)
                            }}><Close /></IconButton>
                        </div>
                    }
                </div>

                <div className="md:w-1/2 w-full px-[10px] mb-5">
                    {InputField('phone', "Phone Number", 'phone')}
                </div>

                {isWebAlias &&
                    <div className="md:w-1/2 w-full px-[10px] mb-5">
                        <div className='md-3-field'>
                            <TextFieldComponent
                                name="webAlias"
                                type={'text'}
                                label={"Web Alias"}
                                value={fieldValue?.values?.webAlias}
                                onChange={fieldValue?.handleChange}
                                error={!!webAliesError?.isWebAliesError || !!(fieldValue?.errors?.webAlias)}
                                errorMessage={webAliesError?.isWebAliesError || fieldValue?.errors?.webAlias}
                            />
                            <span style={{ fontSize: "13px", marginLeft: "7px" }}>Name your personal website</span>
                        </div>
                    </div>
                }
            </div>
            {!isUserLogin &&
                <div className='row-form -mx-[10px] flex flex-wrap'>
                    <div className="md:w-1/2 w-full px-[10px] mb-5">
                        {InputField('password', "Password", 'password')}
                    </div>
                    <div className="md:w-1/2 w-full px-[10px] mb-5">
                        {InputField('confirm_password', "Confirm Password", 'password')}
                    </div>
                </div>
            }
            {/* <div className='checkbox'>
                <label htmlFor='SmsChecked'>
                    <Field type='checkbox' name='SmsChecked' />
                    <span className="checkbox-label-text f-q-regular">I agree to be contacted via email and SMS.</span>
                </label>
                {fieldValue?.errors?.SmsChecked && <p className='error_age_checkbox'>{fieldValue?.errors?.SmsChecked}</p>}
            </div>

            <div className='checkbox'>
                <label htmlFor='SmsChecked'>
                    <Field type='checkbox' name='ageChecked' />
                    <span className="checkbox-label-text f-q-regular">I am above 18 years old</span>
                </label>
                {fieldValue?.errors?.ageChecked && <p className='error_age_checkbox'>{fieldValue?.errors?.ageChecked}</p>}
            </div> */}
            <div className='actionButton w-full text-center'>
                <ButtonComponent type='submit'>{buttonText}</ButtonComponent>
            </div>
        </RegisterFormStyle>
    )
}

RegisterForms.propTypes = RegisterFormsPropsType;
RegisterForms.defaultProps = {
    buttonText: "Submit",
    isUserCanLogin: false,
    isWebAlies: false,
    isRefferal: true
}
export default RegisterForms;
