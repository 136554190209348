import React from 'react';
import logo from '../../assets/logo.png';
import card from '../../assets/cards.png';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { storeAssigned } from '../../services/Methods/normalMethods';

const Footer = (props) => {
    const { COMMON_TEXTS } = props;

    function _disclosureLink() {
        const { income_disclosure, shipping_disclosure, terms_condition, privacy, refund, procedures_policies, compensation } = COMMON_TEXTS;
        const FOOTER_LINK = ['income', 'shipping_info', 'terms', 'privacy', 'refund'];
        const TEXT = [income_disclosure, shipping_disclosure, terms_condition, privacy, refund, procedures_policies, compensation];
        return _.map(FOOTER_LINK, (row, index) => ({ link: `/${storeAssigned()}/${row}`, text: TEXT[index] }));
    }

    const LinkCss = "inline-block text-sm leading-[21px] text-white opacity-[0.65] transition-all eae-in-out duration-300 hover:opacity-100";

    return (
        <div className="footer-container bg-[#2b3445] text-white pt-9">
            <div className="container max-w-[1260px] mx-auto px-[15px]">
                <div className="grid grid-cols-1 md:grid-cols-4 md:gap-[30px]">
                    <div className="text-center md:text-left md:mb-6 mb-12">
                        <a href="/" className='block max-w-[170px] -mt-1 mb-6 md:mx-0 mx-auto'>
                            <img src={logo} alt="Logo" className="w-full h-full object-contain" />
                        </a>
                        <div className="credit-cards max-w-[190px] md:mx-0 mx-auto">
                            <img src={card} alt="Payment methods" className="w-full h-auto" />
                        </div>
                    </div>
                    <div className="text-center md:text-left mb-6">
                        <h4 className="text-2xl leading-[1.2] font-bold mb-4">{COMMON_TEXTS?.contact_us}</h4>
                        <p className="text-sm leading-[21px] mb-4 text-white opacity-[0.65]">
                            901 Sam Rayburn Highway<br /> Melissa, TX 75454
                        </p>
                        <a href="mailto:support@provizion.com" className="inline-block text-sm leading-[21px] mb-4 text-white opacity-[0.65] transition-all eae-in-out duration-300 hover:opacity-100">support@provizion.com</a>
                    </div>
                    <div className="text-center md:text-left mb-8">
                        <h4 className="text-2xl leading-[1.2] font-bold mb-4">{COMMON_TEXTS?.disclosure}</h4>
                        <ul className="">
                            {_.map(_disclosureLink(), ({ link, text }) => (
                                <li className="text-[0]" key={link}>
                                    <Link className={LinkCss + ' mb-1.5'} to={link}>{text}</Link>
                                </li>
                            ))}
                            <li className="text-[0]" >
                                <Link className={LinkCss + ' mb-1.5'} to="pdf/Provizion_Global_P&P's3.7.25.pdf" target="_blank">
                                    Policies & Procedures
                                </Link>
                            </li>
                            <li className="text-[0]" >
                                <Link className={LinkCss} to="api/Files/documents/CompensationPlan.pdf" target="_blank"> Compensation Plan</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border-[rgba(237,238,246,0.5)] border-[1px] px-5 py-[15px] max-w-[680px] mx-auto rounded-[2px] text-center mb-4">
                    <p className='text-xs text-white opacity-50 leading-[18px] font-normal'>{COMMON_TEXTS?.footer_discription}</p>
                </div>
                <div className="text-center pb-6">
                    <p className='text-xs text-white opacity-50 leading-[18px] font-normal'>© {(new Date().getFullYear())} Provizion. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}
export default Footer;