import logo from "../../assets/logo.png"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MainImge from "../../assets/main-image.jpg"
import SectionLogo from "../../assets/sectionLogo.png"
import gradientImg from "../../assets/gradent_img.jpg"
import './powerline.css';
import PowerlineForm from "./PowerlineForm";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { storeAssigned } from "../../services/Methods/normalMethods";

const Powerline = () => {

    const navigate = useNavigate();
    const handleHome = () => {
        navigate("/")
    }
    const formRef = useRef(null);
    const scrollToForm = () => {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (

        <>


            <section className="bg-[#f4f4f4] 2xl:min-h-screen bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${MainImge})` }} >

                <div class="bar bg-[#0D73B8] text-white py-1 w-full">
                    <div className="container 2xl:max-w-[1706px] mx-auto xl:p-[0_80px] p-[0_15px]" >
                        <Link
                            to={`/${storeAssigned()}/login`} class="text-1xl font-bold text-left">Login</Link>
                    </div>
                </div>
                <header className="relative top-[53px]">
                    <div className="container 2xl:max-w-[1706px] mx-auto xl:p-[0_80px] p-[0_15px]">

                        <div className="" onClick={handleHome}>
                            {/* <img className="max-w-[347px] w-full" src={logo} /> */}
                            <svg className="md:max-w-[347px] md:h-[90px] max-w-[170px] w-full" id="Group_7482" data-name="Group 7482" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="270.592" height="69.695" viewBox="0 0 270.592 69.695">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_4071" data-name="Rectangle 4071" width="270.592" height="69.695" fill="none" />
                                    </clipPath>
                                </defs>
                                <g id="Group_7462" data-name="Group 7462" clip-path="url(#clip-path)">
                                    <path id="Path_2251" data-name="Path 2251" d="M1195.449,59.523h3.7V89.694c-1.161,0-2.272.034-3.378-.027-.242-.013-.527-.323-.69-.564q-3.616-5.332-7.2-10.686-3.949-5.87-7.9-11.739c-.09-.134-.208-.249-.5-.6-.031.74-.071,1.234-.071,1.728q-.006,10.08,0,20.16c0,.245.005.49,0,.735-.023.889-.084.966-.954.986s-1.742,0-2.587,0c-.289-.861-.419-27.424-.155-30.209,1.182,0,2.389-.041,3.59.034.248.016.509.436.7.715q3.078,4.56,6.134,9.137,4.32,6.443,8.637,12.888a4.79,4.79,0,0,1,.309.649l.374-.234Z" transform="translate(-928.558 -46.979)" fill="#fbc60c" />
                                    <path id="Path_2252" data-name="Path 2252" d="M273.129,29.655c2.5,0,4.914-.048,7.325.015a35.072,35.072,0,0,1,5.843.4c8.039,1.586,9.7,8.508,8.216,14-1.055,3.9-3.855,6.029-7.63,7.034a22.256,22.256,0,0,1-5.944.492c-1.083-.007-2.166,0-3.453,0-.014,4.825,0,9.593-.01,14.407h-4.345Zm4.369,17.74c1.78,0,3.487.061,5.187-.022a15.975,15.975,0,0,0,3.3-.505,5.853,5.853,0,0,0,3.588-2.558,6.617,6.617,0,0,0-3.9-10.035c-2.682-.747-5.408-.286-8.177-.43Z" transform="translate(-215.74 -23.41)" fill="#0772b8" />
                                    <path id="Path_2253" data-name="Path 2253" d="M1009.464,72.458a15.246,15.246,0,0,1,4.073-10.932,14.08,14.08,0,0,1,9.819-4.587A14.976,14.976,0,0,1,1032.211,59a14.072,14.072,0,0,1,5.943,7.123,17.133,17.133,0,0,1,.946,9.363,15.562,15.562,0,0,1-4.506,8.681,13.616,13.616,0,0,1-6.461,3.517,14.559,14.559,0,0,1-12.3-2.211,14.877,14.877,0,0,1-6.366-12.389c0-.175,0-.35,0-.626m25.886-.514a11.536,11.536,0,0,0-1.433-5.572,10.588,10.588,0,0,0-17.288-2.524,13.231,13.231,0,0,0-1.258,15.6,10.461,10.461,0,0,0,15.381,2.986c3.179-2.484,4.6-5.88,4.6-10.492" transform="translate(-797.356 -44.947)" fill="#fbc60c" />
                                    <path id="Path_2254" data-name="Path 2254" d="M534.125,73.658A14.854,14.854,0,0,1,527.66,85.62c-7.344,5.147-19.213,2.824-22.549-7.661-1.957-6.149-.929-11.878,3.641-16.644A14.04,14.04,0,0,1,520.6,57.133c7.967.66,13.523,7.3,13.52,16.525m-14.872-13.1a10.158,10.158,0,0,0-7.542,3.105c-4.552,4.568-4.735,12.475-.406,17.5a10.489,10.489,0,0,0,15.527.258,13.194,13.194,0,0,0,1.107-16.209,10.256,10.256,0,0,0-8.686-4.657" transform="translate(-398.234 -45.076)" fill="#0772b8" />
                                    <path id="Path_2255" data-name="Path 2255" d="M400.575,59.595a1.674,1.674,0,0,1,.344-.08c3.009.018,6.03-.125,9.023.106,3.568.275,6.419,1.9,7.891,5.37a8.629,8.629,0,0,1-5.552,11.747c-.333.1-.668.193-1.12.323l8.312,12.55c-1.487,0-2.792.043-4.09-.034-.282-.017-.6-.419-.8-.715q-3.322-4.974-6.607-9.972c-1.056-1.6-1.054-1.6-3.011-1.559-.206,0-.411.029-.755.054V89.563h-3.636Zm3.615,14.227c1.82,0,3.533.033,5.243-.018a6.774,6.774,0,0,0,1.841-.377,5.338,5.338,0,0,0-.175-10.1c-2.261-.673-4.563-.266-6.909-.385Z" transform="translate(-316.407 -46.988)" fill="#0772b8" />
                                    <path id="Path_2256" data-name="Path 2256" d="M847.873,62.931H832.151c0-1.1-.021-2.063.02-3.026.007-.171.249-.386.434-.481a1.435,1.435,0,0,1,.619-.051q10.027,0,20.053,0c.237,0,.475.036.968.075L837.427,85.934c1.247.019,2.266.042,3.285.049,1.05.008,2.1,0,3.15,0h3.15l3.15,0h3.2V89.6c-7.388.013-14.744.006-22.406,0,5.629-8.991,11.248-17.687,16.914-26.674" transform="translate(-656.36 -46.891)" fill="#fbc60c" />
                                    <path id="Path_2257" data-name="Path 2257" d="M650.309,59.35h4.146l8.44,21.331.3.009c.176-.391.367-.776.525-1.175q3.833-9.694,7.65-19.395c.23-.587.515-.922,1.217-.876.934.062,1.875.015,2.811.015-.012.97-10.439,26.89-12.365,30.817a3.271,3.271,0,0,1-.481-.634q-3.4-8.351-6.78-16.71c-1.788-4.4-3.588-8.8-5.459-13.384" transform="translate(-513.668 -46.793)" fill="#fbc70f" />
                                    <path id="Path_2258" data-name="Path 2258" d="M676.747,234.562l-2.026,2.156a6.517,6.517,0,0,0-4.5-1.842,6.326,6.326,0,0,0-.506,12.617,17.03,17.03,0,0,0,3.3-.4.916.916,0,0,0,.847-1.1c-.045-.939-.012-1.881-.012-2.952h-3.982v-2.92a2.7,2.7,0,0,1,.412-.065c2.168,0,4.335,0,6.564,0a5.715,5.715,0,0,1,.1.731c.008,2.239,0,4.479.01,6.718a1.136,1.136,0,0,1-.465,1.076c-4.035,2.914-11.128,2.728-14.322-2.55a9.21,9.21,0,0,1,7.469-14.05,9.105,9.105,0,0,1,7.105,2.579" transform="translate(-521.98 -183.225)" fill="#0d73b8" />
                                    <rect id="Rectangle_4070" data-name="Rectangle 4070" width="3.674" height="30.066" transform="translate(166.159 12.566)" fill="#fbc70f" />
                                    <path id="Path_2259" data-name="Path 2259" d="M968.35,89.862h-3.542V59.755h3.519c.28.812.323,28.853.023,30.107" transform="translate(-762.085 -47.199)" fill="#fbc70f" />
                                    <path id="Path_2260" data-name="Path 2260" d="M1034.5,253.27c2.21,1.656,2.612,4.3.794,6.293a4.833,4.833,0,0,1-3.544,1.59c-1.92.053-3.843.013-5.855.013v-15.25a62.59,62.59,0,0,1,6.383.305,3.744,3.744,0,0,1,3.285,4.471,10.889,10.889,0,0,1-1.062,2.577m-5.961,5.457c1.221-.058,2.408-.024,3.565-.194a1.728,1.728,0,0,0,1.6-1.883,1.8,1.8,0,0,0-1.656-1.856c-1.161-.117-2.343-.027-3.578-.027,0,1.164,0,2.243,0,3.322,0,.166.034.331.068.638m-.041-6.544c.9,0,1.7,0,2.5,0a2.39,2.39,0,0,0,.516-.071,1.576,1.576,0,0,0,1.4-1.681,1.55,1.55,0,0,0-1.309-1.752,25.785,25.785,0,0,0-3.1-.029Z" transform="translate(-810.334 -194.245)" fill="#0d73b8" />
                                    <path id="Path_2261" data-name="Path 2261" d="M903.905,260.758a7.852,7.852,0,1,1,7.786-7.75,7.535,7.535,0,0,1-7.786,7.75m5.07-7.436c-.007-2.967-1.493-5.048-3.713-5.577a4.9,4.9,0,0,0-5.863,3,5.6,5.6,0,0,0,2.076,6.826,4.787,4.787,0,0,0,4.987.018,4.94,4.94,0,0,0,2.514-4.271" transform="translate(-707.988 -193.567)" fill="#0d73b8" />
                                    <path id="Path_2262" data-name="Path 2262" d="M86.438,196.518,87.57,161.81a1.448,1.448,0,0,1,.343-.106c1.988.191,1.993.192,1.976,2.172q-.131,15.958-.266,31.915c-.01,1.243-.128,1.353-1.319,1.14-.582-.1-1.156-.254-1.867-.412" transform="translate(-68.276 -127.727)" fill="#fbc70f" />
                                    <path id="Path_2263" data-name="Path 2263" d="M142.612,163.157l2.35-.3c.349,11.576.695,23.021,1.044,34.6l-2.983.568c-.051-.9-.121-1.615-.128-2.335-.039-3.953-.055-7.905-.092-11.858q-.088-9.6-.19-19.2c0-.45,0-.9,0-1.489" transform="translate(-112.646 -128.641)" fill="#fbc70f" />
                                    <path id="Path_2264" data-name="Path 2264" d="M69.068,192.523c-1.054-.383-1.915-.642-2.716-1.026-.206-.1-.324-.6-.31-.906.11-2.41.267-4.819.4-7.228.2-3.666.38-7.334.594-11,.228-3.909.49-7.815.724-11.724.054-.908.052-1.82.088-2.729.011-.271.064-.541.12-.989l2.34.656c-.411,11.6-.819,23.128-1.238,34.945" transform="translate(-52.164 -123.949)" fill="#fbc70f" />
                                    <path id="Path_2265" data-name="Path 2265" d="M161.173,194.207c-.1-1.411-.22-2.655-.263-3.9-.117-3.393-.188-6.788-.3-10.181-.129-3.881-.279-7.761-.43-11.64-.1-2.62-.218-5.24-.331-7.86-.04-.933.048-1.048,1-1.249.137-.029.275-.054.412-.082.953-.2,1.026-.147,1.073.811.165,3.389.319,6.778.487,10.166.2,4.051.4,8.1.623,12.151.177,3.211.394,6.421.579,9.632.064,1.111.051,1.126-.976,1.529-.548.215-1.117.375-1.874.624" transform="translate(-126.255 -125.75)" fill="#fac713" />
                                    <path id="Path_2266" data-name="Path 2266" d="M109.4,199.347a2.429,2.429,0,0,1-.387.145c-2.457.188-2.458.188-2.439-2.231.029-3.57.053-7.14.092-10.71q.093-8.4.209-16.794c.026-1.744.114-3.488.176-5.326a15.9,15.9,0,0,1,1.963.032c.167.021.4.439.4.675.012,3.639-.02,7.279-.023,10.918q-.008,10.974,0,21.948v1.342" transform="translate(-84.18 -129.871)" fill="#fac713" />
                                    <path id="Path_2267" data-name="Path 2267" d="M128.686,199.526l-2.659.228V164.8l2.191-.125c.034.358.09.687.094,1.017q.134,12.173.26,24.345c.03,2.726.077,5.452.114,8.178.006.408,0,.816,0,1.306" transform="translate(-99.547 -130.078)" fill="#fbc70f" />
                                    <path id="Path_2268" data-name="Path 2268" d="M1127.175,258.035c-.408,1.028-.811,1.923-1.113,2.852a.955.955,0,0,1-1.157.758c-.549-.05-1.106-.01-1.838-.01l5.9-15.164h2.708l5.917,15.161c-.864,0-1.561.045-2.247-.025a.992.992,0,0,1-.649-.524c-.361-.795-.614-1.639-.982-2.43a1.077,1.077,0,0,0-.707-.591c-1.92-.049-3.842-.027-5.834-.027m3.15-8.219-2.193,5.709h4.4l-2.21-5.709" transform="translate(-887.091 -194.683)" fill="#0d73b8" />
                                    <path id="Path_2269" data-name="Path 2269" d="M113.806,11.872c-2.265.917-4.4,1.606-6.374,2.616a21.932,21.932,0,0,0-10.46,11.947,37.184,37.184,0,0,0-2.15,8.614c-.138.973-.141.972-1.1.911-.244-.016-.49-.023-.732-.051-.408-.048-.643-.255-.583-.7.09-.657.176-1.314.278-1.969a32.909,32.909,0,0,1,3.437-10.616,24.6,24.6,0,0,1,15.025-12.238,1.066,1.066,0,0,1,.793.019c.648.444,1.242.966,1.864,1.464" transform="translate(-72.984 -8.153)" fill="#fac713" />
                                    <path id="Path_2270" data-name="Path 2270" d="M96.407,1.342c-.569.234-1.136.473-1.708.7a24.774,24.774,0,0,0-11.822,9.635,32.773,32.773,0,0,0-4.955,13.457c-.1.688-.266,1.366-.437,2.231l-2.175-.433c.114-1.016.162-1.92.322-2.8a36.043,36.043,0,0,1,5.445-13.962A23.839,23.839,0,0,1,87.66,3.349C89.261,2.3,90.947,1.387,92.59.4a1.927,1.927,0,0,1,2.08-.068,18.173,18.173,0,0,0,1.766.753l-.029.254" transform="translate(-59.486 -0.05)" fill="#fac713" />
                                    <path id="Path_2271" data-name="Path 2271" d="M130.4,25.788c-1.66.479-3.356.88-4.989,1.458A18.52,18.52,0,0,0,114.4,37.523a26.857,26.857,0,0,0-2.188,8.161,5.066,5.066,0,0,1-.124.615c-.056.233-.123.463-.188.7h-2.219c.071-.818.081-1.577.21-2.316a31.277,31.277,0,0,1,2.79-9.077,21.57,21.57,0,0,1,13.6-11.17c.268-.08.537-.158.805-.237,1.736-.51,2.595-.087,3.316,1.585" transform="translate(-86.636 -18.957)" fill="#fac713" />
                                    <path id="Path_2272" data-name="Path 2272" d="M75.551,134.349a39.579,39.579,0,0,0,20.042.115V138.1a34.86,34.86,0,0,1-20.042-.162Z" transform="translate(-59.676 -106.12)" fill="#0772b8" />
                                    <path id="Path_2273" data-name="Path 2273" d="M128.866,58.014l-2.2.112a1.788,1.788,0,0,1-.105-.476c.567-4.681,1.785-9.111,4.873-12.844a17.726,17.726,0,0,1,7.935-5.617c1.413-.49,2.884-.816,4.331-1.207a1.561,1.561,0,0,1,1.883.729c.241.377.516.733.882,1.249-.69.13-1.251.26-1.82.338a18.044,18.044,0,0,0-8.765,3.473,15.462,15.462,0,0,0-5.5,7.741c-.572,1.823-.9,3.724-1.328,5.591-.062.268-.11.539-.185.91" transform="translate(-99.964 -29.94)" fill="#fac713" />
                                    <path id="Path_2274" data-name="Path 2274" d="M143.457,69.809c.1-.9.137-1.656.284-2.392a18.258,18.258,0,0,1,4.569-9.383,15.9,15.9,0,0,1,7.809-4.453c1.014-.25,2.061-.362,3.086-.574a1.6,1.6,0,0,1,2.055.938c.163.372.369.725.576,1.127-1.249.173-2.382.323-3.512.488a13.877,13.877,0,0,0-11.769,10.5c-.224.841-.368,1.7-.528,2.561-.115.614-.359,1.022-1.081,1.006a11.041,11.041,0,0,0-1.488.184" transform="translate(-113.314 -41.816)" fill="#fac713" />
                                    <path id="Path_2275" data-name="Path 2275" d="M1245.012,246.486h2.56v12.508c2.173.247,4.29.012,6.427.149v2.427h-8.986Z" transform="translate(-983.414 -194.695)" fill="#0d73b8" />
                                    <path id="Path_2276" data-name="Path 2276" d="M806.191,246.506H808.8v12.578h6.345v2.509h-8.959Z" transform="translate(-636.796 -194.711)" fill="#0772b8" />
                                    <path id="Path_2277" data-name="Path 2277" d="M160.109,81.041c.191-.944.3-1.725.508-2.481a13.5,13.5,0,0,1,10.624-10.277c1.116-.258,2.289-.26,3.428-.429a1.164,1.164,0,0,1,1.387.806c.2.471.415.934.707,1.59-.6,0-1.007,0-1.411,0a13.9,13.9,0,0,0-6.784,1.509,11.454,11.454,0,0,0-5.77,7.975c-.15.629-.407.915-.966,1.024-.5.1-1.01.168-1.724.284" transform="translate(-126.467 -53.58)" fill="#fac713" />
                                    <path id="Path_2278" data-name="Path 2278" d="M0,85.692c.339-.826.6-1.532.925-2.2a.848.848,0,0,1,.6-.327,21.117,21.117,0,0,1,8.017.248,10.63,10.63,0,0,1,5.319,3.173,1.473,1.473,0,0,1,.456.886c-.064,1-.241,1.992-.4,3.184-.661-.887-1.172-1.612-1.723-2.3A8.26,8.26,0,0,0,8.4,85.583a19.824,19.824,0,0,0-7.49.065c-.23.033-.466.024-.907.045" transform="translate(0 -65.481)" fill="#fac713" />
                                    <path id="Path_2279" data-name="Path 2279" d="M8.76,66.5c1.37-3.037,1.079-2.472,3.575-2.446a15.829,15.829,0,0,1,9.824,3.081c1.26.972,1.277.953.847,2.522-.089.326-.2.647-.295.969-1.408-.979-2.708-2-4.118-2.832a10.317,10.317,0,0,0-4.719-1.274c-1.66-.094-3.328-.021-5.115-.021" transform="translate(-6.919 -50.548)" fill="#fac713" />
                                    <path id="Path_2280" data-name="Path 2280" d="M20.631,47.567a1.841,1.841,0,0,1,.023-.315c1.027-2.026,1.134-2.064,3.386-1.835a16.91,16.91,0,0,1,10.043,4.413l-.838,2.4c-3.564-3.531-7.9-4.624-12.615-4.66" transform="translate(-16.296 -35.799)" fill="#fac713" />
                                    <path id="Path_2281" data-name="Path 2281" d="M46.438,32.56,45.272,34.7a19.425,19.425,0,0,0-11.228-4.711c.619-1.65,1.62-2.249,3.109-1.921A21.751,21.751,0,0,1,44.4,31.021c.693.45,1.325.995,2.04,1.539" transform="translate(-26.891 -22.102)" fill="#fac713" />
                                    <path id="Path_2282" data-name="Path 2282" d="M49.544,13.968a7.2,7.2,0,0,1,2.078-1.781.589.589,0,0,1,.407.012,21.075,21.075,0,0,1,8.4,4.52,1.788,1.788,0,0,1,.174.265l-1.23,1.843a22.231,22.231,0,0,0-9.827-4.859" transform="translate(-39.134 -9.601)" fill="#fac713" />
                                    <path id="Path_2283" data-name="Path 2283" d="M69.659,1.082a18.545,18.545,0,0,1,2-1.023,1.421,1.421,0,0,1,1.085.122c1.788,1.072,3.569,2.16,5.3,3.323.943.635.908.868.144,1.723-.228.256-.466.5-.793.855a24.262,24.262,0,0,0-7.71-4.711l-.03-.289" transform="translate(-55.022 0)" fill="#fac713" />
                                </g>
                            </svg>

                        </div>

                    </div>
                </header>


                <div className="container 2xl:max-w-[1706px] mx-auto 2xl:p-[300px_80px_350px] xl:p-[300px_80px_350px] p-[200px_15px_200px]">

                    <div className="max-w-[670px]">
                        <h1 className="xl:text-[52px] md:text-[40px] text-[30px] text-black xl:leading-[62px] md:leading-[50px] leading-[40px] xl:mb-[73px] mb-[20px] font-light">EXPERIENCE PROVIZION & YOUR BETTER LIFE NOW!</h1>
                        <button onClick={scrollToForm} className="bg-[#0D73B8] border-2 transition duration-500 ease-in-out border-[#0D73B8] text-white xl:text-[30px] text-[20px] xl:min-h-[81px] xl:max-w-[524px] max-w-[max-content] xl:p-[15px] p-[8px_30px] w-full hover:bg-white hover:text-[#0D73B8] font-light">TAKE THE TOUR</button>
                    </div>
                </div>
            </section>
            {/* flex justify-center items-center min-h-screen bg-[radial-gradient(circle,_rgba(240,245,249,1)_0%,_rgba(149,180,199,1)_100%);] */}
            <section className="flex justify-center items-center xl:min-h-screen bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${gradientImg})` }}>

                <div className="container 2xl:max-w-[1492px] mx-auto xl:p-[80px_80px] p-[80px_15px]">

                    <div className="flex flex-col justify-center items-center">
                        {/* <img className="max-w-[812px] w-full" src={SectionLogo} /> */}
                        <svg className="xl:max-w-[815px] xl:min-h-[210px]  md:max-w-[600px] md:min-h-[100px] max-w-[400px] w-full" id="Group_7482" data-name="Group 7482" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="270.592" height="69.695" viewBox="0 0 270.592 69.695">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_4071" data-name="Rectangle 4071" width="270.592" height="69.695" fill="none" />
                                </clipPath>
                            </defs>
                            <g id="Group_7462" data-name="Group 7462" clip-path="url(#clip-path)">
                                <path id="Path_2251" data-name="Path 2251" d="M1195.449,59.523h3.7V89.694c-1.161,0-2.272.034-3.378-.027-.242-.013-.527-.323-.69-.564q-3.616-5.332-7.2-10.686-3.949-5.87-7.9-11.739c-.09-.134-.208-.249-.5-.6-.031.74-.071,1.234-.071,1.728q-.006,10.08,0,20.16c0,.245.005.49,0,.735-.023.889-.084.966-.954.986s-1.742,0-2.587,0c-.289-.861-.419-27.424-.155-30.209,1.182,0,2.389-.041,3.59.034.248.016.509.436.7.715q3.078,4.56,6.134,9.137,4.32,6.443,8.637,12.888a4.79,4.79,0,0,1,.309.649l.374-.234Z" transform="translate(-928.558 -46.979)" fill="#fbc60c" />
                                <path id="Path_2252" data-name="Path 2252" d="M273.129,29.655c2.5,0,4.914-.048,7.325.015a35.072,35.072,0,0,1,5.843.4c8.039,1.586,9.7,8.508,8.216,14-1.055,3.9-3.855,6.029-7.63,7.034a22.256,22.256,0,0,1-5.944.492c-1.083-.007-2.166,0-3.453,0-.014,4.825,0,9.593-.01,14.407h-4.345Zm4.369,17.74c1.78,0,3.487.061,5.187-.022a15.975,15.975,0,0,0,3.3-.505,5.853,5.853,0,0,0,3.588-2.558,6.617,6.617,0,0,0-3.9-10.035c-2.682-.747-5.408-.286-8.177-.43Z" transform="translate(-215.74 -23.41)" fill="#0772b8" />
                                <path id="Path_2253" data-name="Path 2253" d="M1009.464,72.458a15.246,15.246,0,0,1,4.073-10.932,14.08,14.08,0,0,1,9.819-4.587A14.976,14.976,0,0,1,1032.211,59a14.072,14.072,0,0,1,5.943,7.123,17.133,17.133,0,0,1,.946,9.363,15.562,15.562,0,0,1-4.506,8.681,13.616,13.616,0,0,1-6.461,3.517,14.559,14.559,0,0,1-12.3-2.211,14.877,14.877,0,0,1-6.366-12.389c0-.175,0-.35,0-.626m25.886-.514a11.536,11.536,0,0,0-1.433-5.572,10.588,10.588,0,0,0-17.288-2.524,13.231,13.231,0,0,0-1.258,15.6,10.461,10.461,0,0,0,15.381,2.986c3.179-2.484,4.6-5.88,4.6-10.492" transform="translate(-797.356 -44.947)" fill="#fbc60c" />
                                <path id="Path_2254" data-name="Path 2254" d="M534.125,73.658A14.854,14.854,0,0,1,527.66,85.62c-7.344,5.147-19.213,2.824-22.549-7.661-1.957-6.149-.929-11.878,3.641-16.644A14.04,14.04,0,0,1,520.6,57.133c7.967.66,13.523,7.3,13.52,16.525m-14.872-13.1a10.158,10.158,0,0,0-7.542,3.105c-4.552,4.568-4.735,12.475-.406,17.5a10.489,10.489,0,0,0,15.527.258,13.194,13.194,0,0,0,1.107-16.209,10.256,10.256,0,0,0-8.686-4.657" transform="translate(-398.234 -45.076)" fill="#0772b8" />
                                <path id="Path_2255" data-name="Path 2255" d="M400.575,59.595a1.674,1.674,0,0,1,.344-.08c3.009.018,6.03-.125,9.023.106,3.568.275,6.419,1.9,7.891,5.37a8.629,8.629,0,0,1-5.552,11.747c-.333.1-.668.193-1.12.323l8.312,12.55c-1.487,0-2.792.043-4.09-.034-.282-.017-.6-.419-.8-.715q-3.322-4.974-6.607-9.972c-1.056-1.6-1.054-1.6-3.011-1.559-.206,0-.411.029-.755.054V89.563h-3.636Zm3.615,14.227c1.82,0,3.533.033,5.243-.018a6.774,6.774,0,0,0,1.841-.377,5.338,5.338,0,0,0-.175-10.1c-2.261-.673-4.563-.266-6.909-.385Z" transform="translate(-316.407 -46.988)" fill="#0772b8" />
                                <path id="Path_2256" data-name="Path 2256" d="M847.873,62.931H832.151c0-1.1-.021-2.063.02-3.026.007-.171.249-.386.434-.481a1.435,1.435,0,0,1,.619-.051q10.027,0,20.053,0c.237,0,.475.036.968.075L837.427,85.934c1.247.019,2.266.042,3.285.049,1.05.008,2.1,0,3.15,0h3.15l3.15,0h3.2V89.6c-7.388.013-14.744.006-22.406,0,5.629-8.991,11.248-17.687,16.914-26.674" transform="translate(-656.36 -46.891)" fill="#fbc60c" />
                                <path id="Path_2257" data-name="Path 2257" d="M650.309,59.35h4.146l8.44,21.331.3.009c.176-.391.367-.776.525-1.175q3.833-9.694,7.65-19.395c.23-.587.515-.922,1.217-.876.934.062,1.875.015,2.811.015-.012.97-10.439,26.89-12.365,30.817a3.271,3.271,0,0,1-.481-.634q-3.4-8.351-6.78-16.71c-1.788-4.4-3.588-8.8-5.459-13.384" transform="translate(-513.668 -46.793)" fill="#fbc70f" />
                                <path id="Path_2258" data-name="Path 2258" d="M676.747,234.562l-2.026,2.156a6.517,6.517,0,0,0-4.5-1.842,6.326,6.326,0,0,0-.506,12.617,17.03,17.03,0,0,0,3.3-.4.916.916,0,0,0,.847-1.1c-.045-.939-.012-1.881-.012-2.952h-3.982v-2.92a2.7,2.7,0,0,1,.412-.065c2.168,0,4.335,0,6.564,0a5.715,5.715,0,0,1,.1.731c.008,2.239,0,4.479.01,6.718a1.136,1.136,0,0,1-.465,1.076c-4.035,2.914-11.128,2.728-14.322-2.55a9.21,9.21,0,0,1,7.469-14.05,9.105,9.105,0,0,1,7.105,2.579" transform="translate(-521.98 -183.225)" fill="#0d73b8" />
                                <rect id="Rectangle_4070" data-name="Rectangle 4070" width="3.674" height="30.066" transform="translate(166.159 12.566)" fill="#fbc70f" />
                                <path id="Path_2259" data-name="Path 2259" d="M968.35,89.862h-3.542V59.755h3.519c.28.812.323,28.853.023,30.107" transform="translate(-762.085 -47.199)" fill="#fbc70f" />
                                <path id="Path_2260" data-name="Path 2260" d="M1034.5,253.27c2.21,1.656,2.612,4.3.794,6.293a4.833,4.833,0,0,1-3.544,1.59c-1.92.053-3.843.013-5.855.013v-15.25a62.59,62.59,0,0,1,6.383.305,3.744,3.744,0,0,1,3.285,4.471,10.889,10.889,0,0,1-1.062,2.577m-5.961,5.457c1.221-.058,2.408-.024,3.565-.194a1.728,1.728,0,0,0,1.6-1.883,1.8,1.8,0,0,0-1.656-1.856c-1.161-.117-2.343-.027-3.578-.027,0,1.164,0,2.243,0,3.322,0,.166.034.331.068.638m-.041-6.544c.9,0,1.7,0,2.5,0a2.39,2.39,0,0,0,.516-.071,1.576,1.576,0,0,0,1.4-1.681,1.55,1.55,0,0,0-1.309-1.752,25.785,25.785,0,0,0-3.1-.029Z" transform="translate(-810.334 -194.245)" fill="#0d73b8" />
                                <path id="Path_2261" data-name="Path 2261" d="M903.905,260.758a7.852,7.852,0,1,1,7.786-7.75,7.535,7.535,0,0,1-7.786,7.75m5.07-7.436c-.007-2.967-1.493-5.048-3.713-5.577a4.9,4.9,0,0,0-5.863,3,5.6,5.6,0,0,0,2.076,6.826,4.787,4.787,0,0,0,4.987.018,4.94,4.94,0,0,0,2.514-4.271" transform="translate(-707.988 -193.567)" fill="#0d73b8" />
                                <path id="Path_2262" data-name="Path 2262" d="M86.438,196.518,87.57,161.81a1.448,1.448,0,0,1,.343-.106c1.988.191,1.993.192,1.976,2.172q-.131,15.958-.266,31.915c-.01,1.243-.128,1.353-1.319,1.14-.582-.1-1.156-.254-1.867-.412" transform="translate(-68.276 -127.727)" fill="#fbc70f" />
                                <path id="Path_2263" data-name="Path 2263" d="M142.612,163.157l2.35-.3c.349,11.576.695,23.021,1.044,34.6l-2.983.568c-.051-.9-.121-1.615-.128-2.335-.039-3.953-.055-7.905-.092-11.858q-.088-9.6-.19-19.2c0-.45,0-.9,0-1.489" transform="translate(-112.646 -128.641)" fill="#fbc70f" />
                                <path id="Path_2264" data-name="Path 2264" d="M69.068,192.523c-1.054-.383-1.915-.642-2.716-1.026-.206-.1-.324-.6-.31-.906.11-2.41.267-4.819.4-7.228.2-3.666.38-7.334.594-11,.228-3.909.49-7.815.724-11.724.054-.908.052-1.82.088-2.729.011-.271.064-.541.12-.989l2.34.656c-.411,11.6-.819,23.128-1.238,34.945" transform="translate(-52.164 -123.949)" fill="#fbc70f" />
                                <path id="Path_2265" data-name="Path 2265" d="M161.173,194.207c-.1-1.411-.22-2.655-.263-3.9-.117-3.393-.188-6.788-.3-10.181-.129-3.881-.279-7.761-.43-11.64-.1-2.62-.218-5.24-.331-7.86-.04-.933.048-1.048,1-1.249.137-.029.275-.054.412-.082.953-.2,1.026-.147,1.073.811.165,3.389.319,6.778.487,10.166.2,4.051.4,8.1.623,12.151.177,3.211.394,6.421.579,9.632.064,1.111.051,1.126-.976,1.529-.548.215-1.117.375-1.874.624" transform="translate(-126.255 -125.75)" fill="#fac713" />
                                <path id="Path_2266" data-name="Path 2266" d="M109.4,199.347a2.429,2.429,0,0,1-.387.145c-2.457.188-2.458.188-2.439-2.231.029-3.57.053-7.14.092-10.71q.093-8.4.209-16.794c.026-1.744.114-3.488.176-5.326a15.9,15.9,0,0,1,1.963.032c.167.021.4.439.4.675.012,3.639-.02,7.279-.023,10.918q-.008,10.974,0,21.948v1.342" transform="translate(-84.18 -129.871)" fill="#fac713" />
                                <path id="Path_2267" data-name="Path 2267" d="M128.686,199.526l-2.659.228V164.8l2.191-.125c.034.358.09.687.094,1.017q.134,12.173.26,24.345c.03,2.726.077,5.452.114,8.178.006.408,0,.816,0,1.306" transform="translate(-99.547 -130.078)" fill="#fbc70f" />
                                <path id="Path_2268" data-name="Path 2268" d="M1127.175,258.035c-.408,1.028-.811,1.923-1.113,2.852a.955.955,0,0,1-1.157.758c-.549-.05-1.106-.01-1.838-.01l5.9-15.164h2.708l5.917,15.161c-.864,0-1.561.045-2.247-.025a.992.992,0,0,1-.649-.524c-.361-.795-.614-1.639-.982-2.43a1.077,1.077,0,0,0-.707-.591c-1.92-.049-3.842-.027-5.834-.027m3.15-8.219-2.193,5.709h4.4l-2.21-5.709" transform="translate(-887.091 -194.683)" fill="#0d73b8" />
                                <path id="Path_2269" data-name="Path 2269" d="M113.806,11.872c-2.265.917-4.4,1.606-6.374,2.616a21.932,21.932,0,0,0-10.46,11.947,37.184,37.184,0,0,0-2.15,8.614c-.138.973-.141.972-1.1.911-.244-.016-.49-.023-.732-.051-.408-.048-.643-.255-.583-.7.09-.657.176-1.314.278-1.969a32.909,32.909,0,0,1,3.437-10.616,24.6,24.6,0,0,1,15.025-12.238,1.066,1.066,0,0,1,.793.019c.648.444,1.242.966,1.864,1.464" transform="translate(-72.984 -8.153)" fill="#fac713" />
                                <path id="Path_2270" data-name="Path 2270" d="M96.407,1.342c-.569.234-1.136.473-1.708.7a24.774,24.774,0,0,0-11.822,9.635,32.773,32.773,0,0,0-4.955,13.457c-.1.688-.266,1.366-.437,2.231l-2.175-.433c.114-1.016.162-1.92.322-2.8a36.043,36.043,0,0,1,5.445-13.962A23.839,23.839,0,0,1,87.66,3.349C89.261,2.3,90.947,1.387,92.59.4a1.927,1.927,0,0,1,2.08-.068,18.173,18.173,0,0,0,1.766.753l-.029.254" transform="translate(-59.486 -0.05)" fill="#fac713" />
                                <path id="Path_2271" data-name="Path 2271" d="M130.4,25.788c-1.66.479-3.356.88-4.989,1.458A18.52,18.52,0,0,0,114.4,37.523a26.857,26.857,0,0,0-2.188,8.161,5.066,5.066,0,0,1-.124.615c-.056.233-.123.463-.188.7h-2.219c.071-.818.081-1.577.21-2.316a31.277,31.277,0,0,1,2.79-9.077,21.57,21.57,0,0,1,13.6-11.17c.268-.08.537-.158.805-.237,1.736-.51,2.595-.087,3.316,1.585" transform="translate(-86.636 -18.957)" fill="#fac713" />
                                <path id="Path_2272" data-name="Path 2272" d="M75.551,134.349a39.579,39.579,0,0,0,20.042.115V138.1a34.86,34.86,0,0,1-20.042-.162Z" transform="translate(-59.676 -106.12)" fill="#0772b8" />
                                <path id="Path_2273" data-name="Path 2273" d="M128.866,58.014l-2.2.112a1.788,1.788,0,0,1-.105-.476c.567-4.681,1.785-9.111,4.873-12.844a17.726,17.726,0,0,1,7.935-5.617c1.413-.49,2.884-.816,4.331-1.207a1.561,1.561,0,0,1,1.883.729c.241.377.516.733.882,1.249-.69.13-1.251.26-1.82.338a18.044,18.044,0,0,0-8.765,3.473,15.462,15.462,0,0,0-5.5,7.741c-.572,1.823-.9,3.724-1.328,5.591-.062.268-.11.539-.185.91" transform="translate(-99.964 -29.94)" fill="#fac713" />
                                <path id="Path_2274" data-name="Path 2274" d="M143.457,69.809c.1-.9.137-1.656.284-2.392a18.258,18.258,0,0,1,4.569-9.383,15.9,15.9,0,0,1,7.809-4.453c1.014-.25,2.061-.362,3.086-.574a1.6,1.6,0,0,1,2.055.938c.163.372.369.725.576,1.127-1.249.173-2.382.323-3.512.488a13.877,13.877,0,0,0-11.769,10.5c-.224.841-.368,1.7-.528,2.561-.115.614-.359,1.022-1.081,1.006a11.041,11.041,0,0,0-1.488.184" transform="translate(-113.314 -41.816)" fill="#fac713" />
                                <path id="Path_2275" data-name="Path 2275" d="M1245.012,246.486h2.56v12.508c2.173.247,4.29.012,6.427.149v2.427h-8.986Z" transform="translate(-983.414 -194.695)" fill="#0d73b8" />
                                <path id="Path_2276" data-name="Path 2276" d="M806.191,246.506H808.8v12.578h6.345v2.509h-8.959Z" transform="translate(-636.796 -194.711)" fill="#0772b8" />
                                <path id="Path_2277" data-name="Path 2277" d="M160.109,81.041c.191-.944.3-1.725.508-2.481a13.5,13.5,0,0,1,10.624-10.277c1.116-.258,2.289-.26,3.428-.429a1.164,1.164,0,0,1,1.387.806c.2.471.415.934.707,1.59-.6,0-1.007,0-1.411,0a13.9,13.9,0,0,0-6.784,1.509,11.454,11.454,0,0,0-5.77,7.975c-.15.629-.407.915-.966,1.024-.5.1-1.01.168-1.724.284" transform="translate(-126.467 -53.58)" fill="#fac713" />
                                <path id="Path_2278" data-name="Path 2278" d="M0,85.692c.339-.826.6-1.532.925-2.2a.848.848,0,0,1,.6-.327,21.117,21.117,0,0,1,8.017.248,10.63,10.63,0,0,1,5.319,3.173,1.473,1.473,0,0,1,.456.886c-.064,1-.241,1.992-.4,3.184-.661-.887-1.172-1.612-1.723-2.3A8.26,8.26,0,0,0,8.4,85.583a19.824,19.824,0,0,0-7.49.065c-.23.033-.466.024-.907.045" transform="translate(0 -65.481)" fill="#fac713" />
                                <path id="Path_2279" data-name="Path 2279" d="M8.76,66.5c1.37-3.037,1.079-2.472,3.575-2.446a15.829,15.829,0,0,1,9.824,3.081c1.26.972,1.277.953.847,2.522-.089.326-.2.647-.295.969-1.408-.979-2.708-2-4.118-2.832a10.317,10.317,0,0,0-4.719-1.274c-1.66-.094-3.328-.021-5.115-.021" transform="translate(-6.919 -50.548)" fill="#fac713" />
                                <path id="Path_2280" data-name="Path 2280" d="M20.631,47.567a1.841,1.841,0,0,1,.023-.315c1.027-2.026,1.134-2.064,3.386-1.835a16.91,16.91,0,0,1,10.043,4.413l-.838,2.4c-3.564-3.531-7.9-4.624-12.615-4.66" transform="translate(-16.296 -35.799)" fill="#fac713" />
                                <path id="Path_2281" data-name="Path 2281" d="M46.438,32.56,45.272,34.7a19.425,19.425,0,0,0-11.228-4.711c.619-1.65,1.62-2.249,3.109-1.921A21.751,21.751,0,0,1,44.4,31.021c.693.45,1.325.995,2.04,1.539" transform="translate(-26.891 -22.102)" fill="#fac713" />
                                <path id="Path_2282" data-name="Path 2282" d="M49.544,13.968a7.2,7.2,0,0,1,2.078-1.781.589.589,0,0,1,.407.012,21.075,21.075,0,0,1,8.4,4.52,1.788,1.788,0,0,1,.174.265l-1.23,1.843a22.231,22.231,0,0,0-9.827-4.859" transform="translate(-39.134 -9.601)" fill="#fac713" />
                                <path id="Path_2283" data-name="Path 2283" d="M69.659,1.082a18.545,18.545,0,0,1,2-1.023,1.421,1.421,0,0,1,1.085.122c1.788,1.072,3.569,2.16,5.3,3.323.943.635.908.868.144,1.723-.228.256-.466.5-.793.855a24.262,24.262,0,0,0-7.71-4.711l-.03-.289" transform="translate(-55.022 0)" fill="#fac713" />
                            </g>
                        </svg>



                        <button className="group xl:mt-[120px] mt-[50px] xl:text-[50px] md:text-[40px] text-[30px] font-light text-[#0D73B8] xl:max-w-[443px] max-w-[max-content] xl:min-h-[116px]  min-h-[auto] xl:p-[8px] p-[8px_20px] w-full flex justify-center transition duration-500 ease-in-out items-center xl:gap-[38px] gap-[8px] bg-white rounded-full hover:bg-[#0D73B8] hover:text-white"> <PlayCircleOutlineIcon className="text-[#0D73B8] xl:!w-[69px] xl:!h-[69px] md:!w-[50px] md:!h-[50px] w-[40px] !h-[40px] group-hover:fill-white" />   Play Video</button>

                    </div>
                </div>
            </section>

            <section>
                <div className="container 2xl:max-w-[1365px] mx-auto xl:p-[120px_80px_116px] md:p-[80px_15px_80px] p-[50px_15px_50px]">
                    <h2 className="xl:mb-[50px] mb-[20px] text-center xl:text-[80px] lg:text-[60px] md:text-[50px] text-[30px] font-light text-[#0D73B8]">Why Choose <span className="font-bold">Provizion?</span></h2>
                    <p className="xl:mb-[90px] mb-[30px] xl:text-[36px] lg:text-[30px] md:text-[25px] text-[20px] text-[#292929] xl:leading-[50px] leading-[1.5em] font-light tracking-[0.72px]">Discover a world of possibilities with Provizion. Whether you’re looking for cutting-edge products or a dynamic business opportunity, Provizion offers the perfect platform to achieve your goals. With a proven system designed for success, flexible customer options, and a supportive global community, Provizion empowers you to take control of your future. Join us and unlock your potential with a network of like-minded individuals dedicated to growth, innovation, and success.</p>
                    <button onClick={scrollToForm} className="border-2 border-[#FBC60C] bg-[#FBC60C] mx-auto block text-black xl:text-[30px] lg:text-[25px] md:text-[20px] text-[18px] xl:min-h-[81px] min-h-[auto] max-w-[524px] p-[15px] w-full hover:bg-white transition duration-500 ease-in-out">TAKE THE TOUR</button>
                </div>
            </section>


            <section className="bg-[#CADEE9]" ref={formRef}>
                <div className="container 2xl:max-w-[1490px] mx-auto xl:p-[120px_80px_116px] lg:p-[80px_15px_80px] p-[50px_15px_50px]">
                    <h2 className="xl:mb-[40px] mb-[20px] xl:leading-[80px] leading-[1.2em] text-center xl:text-[60px] lg:text-[50px] md:text-[40px] text-[30px] font-light text-black">EXPLORE <span className="font-bold">PROVIZION</span></h2>
                    <p className="xl:text-[30px] lg:text-[20px] md:text-[18px] text-[18px] text-black text-center font-normal">Ready to take the next step? Explore what Provizion has to offer!</p>
                    <div className="max-w-[852px] w-full mx-auto xl:mt-[100px] lg:mt-[50px] mt-[30px]">
                        <PowerlineForm />
                    </div>
                </div>
            </section>

            <footer className="bg-black">
                <div className="container mx-auto 2xl:max-w-[1646px] w-full xl:p-[20px_80px] p-[20px_15px] flex items-end justify-between">
                    <p className="text-white md:text-[20px] text-[16px] font-light">© 2024 Provizion</p>
                    <img className="xl:max-w-[310px] lg:max-w-[170px] max-w-[150px] w-full" src={logo} />
                </div>
            </footer>
        </>
    );
};

export default Powerline;