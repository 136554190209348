import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { calculateReouccringPayload, _paymentPayload, _checkoutAddressFormat } from "../../../services/Methods/checkoutPayloads";
import { CalculateAutoshipApi, ModifyCalculateAutoshipApi } from "../../../services/Redux/Reducer/CheckoutSlice";
import _ from "lodash";
import moment from "moment";
import { getCustomerSavedCard } from "../../../services/Redux/Reducer/CustomerSlice";
import { _scriptFunctionCall, loopFunction } from "../../../services/Methods/commonPayload";
import { getAutoShipOrders } from "../../../services/Redux/Reducer/OrderSlice";
import { createSelector } from "reselect";
import { _getPaymentHtml, _getPaymentOptions } from "../../../services/Redux/Reducer/PaymentSlice";

export const autoshipProduct = (data, priceType) => _.map(data, (item, index) => ({     /** autoship order */
    parentItemId: 0, "parentLineNumber": 0, orderLineNumber: index + 1, itemId: item?.id || item?.itemId, itemCode: item?.fullItemCode || item?.itemCode, quantity: item?.quantity, priceType: priceType || item?.priceType
}));

const useAutoshipEditHook = (props) => {
    const { autoshipId, isUserLogin, } = props;
    const dispatch = useDispatch();
    const memorizeSelector = createSelector(
        (state) => state.PaymentSlice,
        (state) => state.CustomerSlice,
        (state) => state?.OrderSlice,
        (state) => state?.CheckoutSlice,
        (data, customerData, autoshipData, checkoutData) => {
            const { paymentOptions, paymentFormHtml } = data;
            const { error, errorAddressData, normalAddressError, isLoading } = checkoutData
            return ({
                paymentOptions, paymentFormHtml,
                savedCards: customerData?.savedCards,
                autoshiporder: autoshipData?.autoshiporder,
                error, errorAddressData, normalAddressError, isLoading
            })
        }
    );

    const {
        paymentOptions, paymentFormHtml, autoshiporder, savedCards, error, errorAddressData, normalAddressError, isLoading
    } = useSelector((state) => memorizeSelector(state));
    /****************************-- Autohship Product --*************************** */

    const [autoshipOrderDetail, setAutohsipOrderDetail] = useState([]);
    const [openProductAdd, setOpenProductAdd] = useState(false);
    const [frequencyTYpe, setFrequencyType] = useState('1');
    const [shippingMethods, setShippingMethods] = useState([]);
    const [sameAsBillingChecked, setSameAsBillingChecked] = useState(false);

    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [priceTypeState, setPriceTypeState] = useState(null);

    /****************************-- Autoship Address Edit --*************************** */

    const [addressEditOpen, setAddressEditOpen] = useState(false);
    const [addressDetails, setAddressDetails] = useState({
        shipping_urname: "", shipping_lastname: "", shipping_country: "", shipping_street: "", shipping_city: "", shipping_state: "", shipping_zipcode: "", shipping_phone: "", shipping_delivery: "",
    });
    const [countryState, setCountryState] = useState({
        country: "", state: "", countryError: "", stateError: ""
    });
    const [updateSuccess, setUpdateSuccess] = useState('');
    const [deletePopUp, setDeletePopup] = useState({ id: null, open: false });
    /****************************-- All Data --*************************** */

    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */
    let calculateAutoshipData = calculateReouccringPayload(addressDetails, selectedAutoshipCheck, useDefaultAddressOrNot)
    const [commonLoader, setCommonLoader] = useState(false);

    // ****************** commmon function ************************//




    function _callAutoshipCalculateApi(autoshipData, shippingChecks, userInfo, defaultAddress, callback) {
        if (autoshipData?.length > 0) {
            userInfo['priceType'] = autoshipData?.[0]?.priceType;
            calculateAutoshipData = calculateReouccringPayload(userInfo, shippingChecks, defaultAddress)
            calculateAutoshipData = { ...calculateAutoshipData, details: autoshipData }
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => {
                _handleCommonLoader(data);
                if (callback) { callback(data); }
            }));
        }
    }

    function _setUseDefaultAddressFunction(data, useDefaultAddressOrNot, userData) {
        const CHANGE_COUNTRY = userData?.shipping_country ? {
            shipping_country: userData?.shipping_country, shipping_state: userData?.shipping_state
        } : countryState;
        const BILLING_DETAIL = _checkoutAddressFormat(data, userData, useDefaultAddressOrNot, CHANGE_COUNTRY);
        setAddressDetails((pre) => ({ ...pre, ...BILLING_DETAIL }));
        _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, userData, useDefaultAddressOrNot, null)
    }
    // ********** saved card , payment section *****************
    const [selectedPayment, setSelectedPayment] = useState({
        cardValue: { previousAmount: null, nextAmount: null, accountType: null },
        selectedPaymentDropdown: null,
        selectedOptionForPayment: 1
    });
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: true });


    /************** Change frequency of autoship *******************/
    function _handleOnSelectedAutoshipChange(data, section) {
        setCommonLoader(true);
        const shippingMethods = {
            shipCarrierId: data?.shipCarrierId,
            shipMethodType: data?.shipMethodType
        };
        _callAutoshipCalculateApi(autoshipOrderDetail, shippingMethods, addressDetails, useDefaultAddressOrNot);
    }

    // *************** Handle address changes submit ***************//
    function _handleAddressEditSubmit(values, actions) {
        if (countryState?.country && countryState?.state) {
            values['shipping_country'] = countryState?.country;
            values['shipping_state'] = countryState?.state;
            setAddressDetails(values);
            setAddressEditOpen(false);
            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, values, useDefaultAddressOrNot);

        } else {
            if (countryState?.country === "") {
                setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
            }
            if (countryState?.state === "") {
                setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
            }
        }
    }

    // **************** Product Add Function **********************

    function _handleProductAdd(data) {
        setCommonLoader(true);
        const ProductAdd = [{ itemId: data?.id, quantity: 1, itemCode: data?.itemCode }];
        const SHIPPING_METHODS = { shipCarrierId: null, shipMethodType: null }
        setOpenProductAdd(false);
        const AUTOSHIP_DATA = autoshipProduct([...autoshipOrderDetail, ...ProductAdd], priceTypeState);
        _callAutoshipCalculateApi(AUTOSHIP_DATA, SHIPPING_METHODS, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) {
                setAutohsipOrderDetail(AUTOSHIP_DATA);
                // setOpenProductAdd(false);
                setCommonLoader(false);
            }
        });
    }
    // ************* Product Delete Function ********************* //
    function _handleProductDelete(id) {
        setCommonLoader(true);
        const REJECTED_DATA = _.reject(autoshipOrderDetail, { itemId: +id })
        const AUTOSHIP_DATA = autoshipProduct(REJECTED_DATA, priceTypeState);
        _openCloseAskPopup(null, 'close');
        _callAutoshipCalculateApi(AUTOSHIP_DATA, selectedAutoshipCheck, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) { setAutohsipOrderDetail(AUTOSHIP_DATA); }
        });
    }

    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPayment((prv) => ({ ...prv, cardValue: { ...prv?.cardValue, previousAmount: data, nextAmount: data }, selectedPaymentDropdown: null }));
        } else {
            setSelectedPayment((prv) => ({ ...prv, cardValue: { ...prv?.cardValue, previousAmount: null, nextAmount: null }, selectedPaymentDropdown: data }));
            if (data === 1) {
                dispatch(_getPaymentHtml(data));
            }
        }
    }

    //open close deleteFunction
    function _openCloseAskPopup(id, switches = "open") {
        if (autoshipOrderDetail?.length > 1) {
            setDeletePopup({ id, open: switches === 'open' ? true : false });
        }
    }


    function handleChangeFunction(event, values) {
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, [name]: value };
            setAddressDetails(USER_DETAILS);
        } else {
            USER_DETAILS = values;
        }
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        if (values?.shipping_city && values?.shipping_street && values?.shipping_zipcode) {

            if (autoshipProduct(autoshipOrderDetail)?.length > 0) {
                calculateAutoshipData['details'] = autoshipProduct(autoshipOrderDetail, priceTypeState);  /** autoship order */
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
        }
    }


    // ************** Product quantity increase and decrease *************** //
    function _handleQuantiyIncDec(id, section) {
        setCommonLoader(true);
        // const SHIPPING_METHODS = { shipCarrierId: null, shipMethodType: null }

        const FIND_PRODUCT = _.find(autoshipOrderDetail, { itemId: +id });
        const FIND_INDEX = _.findIndex(autoshipOrderDetail, { itemId: +id });

        setShippingMethods()
        if (section === 'sub') {
            if (FIND_PRODUCT['quantity'] - 1 > 0) {
                FIND_PRODUCT['quantity'] = (FIND_PRODUCT['quantity'] - 1) > 0 ? FIND_PRODUCT['quantity'] - 1 : 1;
                autoshipOrderDetail[FIND_INDEX] = FIND_PRODUCT;
            } else {
                _openCloseAskPopup(+id, 'open');
                setCommonLoader(false);
            }
        } else {
            FIND_PRODUCT['quantity'] = FIND_PRODUCT['quantity'] + 1;
            autoshipOrderDetail[FIND_INDEX] = FIND_PRODUCT;
        }

        // setSelectedAutoshipCheck(SHIPPING_METHODS);
        const AUTOSHIP_DATA = autoshipProduct(autoshipOrderDetail, priceTypeState);

        const SHIPPING_METHODS = { shipCarrierId: null, shipMethodType: null };
        _callAutoshipCalculateApi(AUTOSHIP_DATA, SHIPPING_METHODS, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) { setAutohsipOrderDetail(AUTOSHIP_DATA); }
        });
    }

    function _handleCommonLoader(data) {
        if (data) {
            setCommonLoader(false);
            setCalculatedAutoshipData(data?.crmOrder);
            setShippingMethods(data?.shipMethods);
            setSelectedAutoshipCheck({
                shipCarrierId: data?.crmOrder?.shipCarrierId,
                shipMethodType: data?.crmOrder?.shipMethodType
            });
        }
    }
    // **************** Update Autoship Product ********************
    function _handleUpdateAutoship() {
        setCommonLoader(true);
        let autoshipCartPayload = _paymentPayload(null, addressDetails, 'autoship', selectedPayment);
        calculateAutoshipData = {
            ...calculateAutoshipData,
            details: autoshipOrderDetail,
            // nextProcessDate: processDate ? processDate : moment.utc().format(),
            nextProcessDate: moment(selectedDate).utc().format(),
            frequencyType: +frequencyTYpe,
            priceType: priceTypeState
        };
        if (selectedPayment?.cardValue?.nextAmount && selectedPayment?.selectedOptionForPayment === 1) {
            autoshipCartPayload['token'] = selectedPayment?.cardValue?.nextAmount?.token;
            autoshipCartPayload['customerAccountId'] = selectedPayment?.cardValue?.nextAmount?.customerAccountId;
            autoshipCartPayload['recurringPaymentActionType'] = 2;
            if (autoshipOrderDetail?.length > 0) {
                calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                dispatch(ModifyCalculateAutoshipApi(
                    _.omit(calculateAutoshipData, ['startDate']), autoshipId, (val) => {
                        setCommonLoader(false);
                        if (val?.data) {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            setUpdateSuccess('You have successfully changed your Autoship order.');
                            setTimeout(() => {
                                setUpdateSuccess('');
                                // window?.location.reload();
                            }, 4000);
                        }
                    }
                ))
            }
        } else {
            _scriptFunctionCall(paymentFormHtml, () => {
                loopFunction(paymentFormHtml, ({ tokenId, preAuthOrderId }) => {
                    if (autoshipOrderDetail?.length > 0 && tokenId) {
                        autoshipCartPayload["token"] = tokenId;
                        autoshipCartPayload['recurringPaymentActionType'] = 1;
                        calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                        dispatch(ModifyCalculateAutoshipApi(
                            _.omit(calculateAutoshipData, ['startDate']), autoshipId, (val) => {
                                setCommonLoader(false);
                                if (val?.data) {
                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                    setUpdateSuccess('You have successfully changed you Autoship order.');
                                    setTimeout(() => {
                                        setUpdateSuccess('');
                                        // window.location.reload();
                                    }, 4000);
                                }
                            }
                        ))
                    }
                });
            });
        }
    }

    //************** add new payment card *********** */
    function _addNewCard(section) {
        // if (section === "add") {
        //     // loadForm();
        //     setSelectedCard((prv) => ({ ...prv, nextAmount: null }));
        // }
        // else {
        //     setSelectedCard((prv) => ({ ...prv, nextAmount: prv?.previousAmount }));
        // }
    }

    useEffect(() => {
        if (autoshipId) {
            dispatch(getAutoShipOrders(autoshipId, null, (res) => {
                setSelectedDate(res?.data?.[0]?.nextProcessDate);
                if (res?.data?.length > 0) {
                    const { shipCountryCode, shipAddress1, payments, shipMethod, shipCity, shipFirstName, shipLastName, shipPostalCode, shipRegionProvState, details, frequencyType, priceType } = res?.data[0];
                    const SHIPPING_DETAILS = {
                        shipping_urname: shipFirstName,
                        shipping_lastname: shipLastName,
                        shipping_country: shipCountryCode,
                        shipping_street: shipAddress1,
                        shipping_city: shipCity,
                        shipping_state: shipRegionProvState,
                        shipping_zipcode: shipPostalCode,
                        shipping_phone: "",
                        shipping_delivery: "",
                    };
                    setCountryState({ country: shipCountryCode, state: shipRegionProvState });
                    const SELECTED_SHIPPING = { shipCarrierId: shipMethod?.shipCarrierId, shipMethodType: shipMethod?.shipMethodType };
                    setSelectedAutoshipCheck(SELECTED_SHIPPING)
                    const AUTOSHIP_DATA = autoshipProduct(details?.filter((r) => r?.parentLineId === 0), priceType);
                    setFrequencyType(frequencyType);
                    setAddressDetails(SHIPPING_DETAILS);
                    setPriceTypeState(priceType);
                    setAutohsipOrderDetail(AUTOSHIP_DATA);

                    if (payments?.length > 0) {
                        setSelectedPayment((prv) => ({ ...prv, cardValue: { accountType: 1, previousAmount: payments[0], nextAmount: payments[0] } }));
                    }
                    _callAutoshipCalculateApi(AUTOSHIP_DATA, SELECTED_SHIPPING, SHIPPING_DETAILS);
                    dispatch(getCustomerSavedCard(isUserLogin, (values) => {
                        if (values?.length > 0 && selectedPayment?.cardValue === null) {
                            setSelectedPayment((prv) => ({ ...prv, cardValue: values[0], selectedOptionForPayment: 1 }));
                        }
                        else if (values?.length === 0) {
                            setSelectedPayment((prv) => ({ ...prv, selectedOptionForPayment: 2 }));
                        }
                    }));
                }
            }));
            dispatch(_getPaymentOptions((data) => {
                if (data[0]?.paymentOptionId) {
                    setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: data[0]?.paymentOptionId }));
                    dispatch(_getPaymentHtml(data[0]?.paymentOptionId));
                }
            }));
        }
    }, [autoshipId]);


    function _paymentOptionSetBilling(address) {
        window.paymentOption?.setBilling({
            street: address?.shipping_street,
            city: address?.shipping_city,
            region: address?.shipping_state,
            postal_code: address?.shipping_zipcode,
            country: address?.shipping_country
        });
    }

    return ({
        _handleUpdateAutoship, updateSuccess,
        autoshipOrderDetail, _handleQuantiyIncDec,
        _handleProductAdd, _handleProductDelete,
        countryState, setCountryState,
        addressEditOpen, setAddressEditOpen,
        openProductAdd, setOpenProductAdd,
        _handleAddressEditSubmit,
        autoshiporder,
        commonLoader,
        calculatedAutoshipData,
        addressDetails, setAddressDetails,
        dispatch,
        shippingMethods,
        selectedAutoshipCheck,
        _handleOnSelectedAutoshipChange,
        errorAddressData,
        normalAddressError,
        useDefaultAddressOrNot, setUseDefaultAddressOrNot,
        frequencyTYpe, setFrequencyType,
        _callAutoshipCalculateApi,
        savedThisCard, setSavedThisCard,
        savedCards, _handleCardChange,
        // selectedCard: selectedCard?.nextAmount, 
        paymentOptions, paymentFormHtml,
        selectedPayment, setSelectedPayment,
        _addNewCard, _paymentOptionSetBilling, sameAsBillingChecked, setSameAsBillingChecked,
        deletePopUp, _openCloseAskPopup,
        selectedDate, setSelectedDate,
        _setUseDefaultAddressFunction,
        handleChangeFunction

    });
}

export default useAutoshipEditHook;