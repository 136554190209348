"use client";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { getAllCustomerData, MyAccoutnAPI, SignUpFormApi, } from "../../services/Redux/Reducer/LoginSlice";
import { MiniLoader, REFFERAL_VALUES, storeAssigned, _setTimeOut, } from "../../services/Methods/normalMethods";
import { VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import COMMON_TEXTS from "../../TextConfigs/common.texts";
import DialogComponent from "../../Inputs/DailogComponent";
import LoaderComponent from "../../Inputs/LoaderComponent";
import TextFieldComponent from "../../Inputs/TextFieldComponent";
import UseCreateAccountHook from "../CreateSignUp/components/CreateAccount.hooks";

const PowerlineForm = (props) => {

    const {
        openPopup, setOpenPopup, webAliesError, handleChange, _validateRefferalFunction, isLoadingTrue,
    } = UseCreateAccountHook(props);

    const dispatch = useDispatch();
    const [emailExistError, setEmailExistError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [processLoader, setProcessLoader] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(true);

    const inputRef = useRef(null);

    const handleInput = () => {
        const inputValue = inputRef.current.value;
        const isInputValid = inputValue.length > 0;
        setButtonDisabled(!isInputValid);
    };

    const { loading } = useSelector((state) => state?.LoginSlice);
    function _emailValidate(data, actions, callback = null) {
        dispatch(
            getAllCustomerData(
                (userDatas) => {
                    if (userDatas?.length > 0) {
                        setEmailExistError("E-mail is already exist");
                        setProcessLoader(false);
                        setTimeout(() => setEmailExistError(""), 5000);
                    } else {
                        callback();
                    }
                    actions.setSubmitting(false);
                },
                null,
                data?.email
            )
        );
    }

    function _handleSubmit(values, actions) {
        try {
            setProcessLoader(true);
            _emailValidate({ ...values }, actions, () => {
                dispatch(
                    SignUpFormApi(values, "visitor", (data) => {
                        console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaa");
                        actions.resetForm({
                            firstName: "", lastName: "", email: "", birthday: "", password: "",
                        });
                        setProcessLoader(false);
                        setSuccessMsg("Thank you! You are now being redirected...");
                        if (data) {
                            setRedirecting(true);
                            dispatch(MyAccoutnAPI(null, data));

                        }
                        setProcessLoader(false);
                        _setTimeOut(() => setSuccessMsg(""), 4000);
                    })
                );
            });
        } catch (error) {
            setProcessLoader(false);
            console.log(error, "Error occurred");
        }
    }
    console.log("REFFERAL_VALUES", REFFERAL_VALUES, openPopup, isLoadingTrue, isButtonDisabled);

    useEffect(() => {
        if (REFFERAL_VALUES === null) {
            setOpenPopup(true);
        }
    }, [])
    console.log(REFFERAL_VALUES, "REFFERAL_VALUES");
    return (
        <>
            {redirecting && <LoaderComponent />}
            {
                !(REFFERAL_VALUES?.webalies.trim()) &&
                <DialogComponent opend={openPopup} handleClose={false} title="Who referred you?" disableOutsideClick classFor="ReferralUser" >
                    <div className='container'>
                        <div className='row' style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "21px" }}>Please provide the referral code from the person who introduced you to Provizion Global</p>
                        </div>
                        {isLoadingTrue ? <LoaderComponent /> : <div className='invitation-code'>
                            <TextFieldComponent type="text" placeholder='Invitation Code (xxxxxx)'
                                onChange={(e) => {
                                    handleChange(e.target.value);
                                    handleInput();
                                }}
                                error={!!webAliesError?.isRefferalError}
                                errorMessage={webAliesError?.isRefferalError}
                                inputRef={inputRef}
                            />
                        </div>
                        }
                    </div>
                    <div className='blank-section'></div>

                    <button type="Submit" disabled={isLoadingTrue || isButtonDisabled} className={`invite-referrer ${isLoadingTrue || isButtonDisabled ? 'disabled' : ''}`} onClick={() => _validateRefferalFunction()}>Ok</button>
                </DialogComponent>
            }



            <div className="w-full">
                <div className="flex items-center justify-end gap-2 mb-2">
                    <span> Already a User? </span>
                    <Link
                        to={`/${storeAssigned()}/login`}
                        className="flex items-center justify-end text-[0px] lg:text-[20px]"
                    >
                        <span className="block text-[16px] leading-4 rounded-full items-center justify-center">
                            Please  <u className="text-blue-400"> Sign In </u>
                        </span>
                    </Link>
                </div>
                <Formik
                    enableReinitialize
                    validationSchema={yup.object().shape({
                        firstName: yup.string().trim().required("Please enter your first name"),
                        lastName: yup.string().trim().required("Please enter your last name"),
                        email: yup.string().trim().required("Please enter your email").email("Invalid email address")
                            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email format"),
                        password: yup
                            .string().trim().required("Please enter your password").min(6, "Password must be at least 6 characters long"),
                    })}
                    initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        birthday: "",
                        password: "",
                    }}
                    onSubmit={(values, actions) => {
                        _handleSubmit(values, actions);
                    }}
                >
                    {({ values, errors, handleChange }) => (
                        <Form className="max-w-[852px] w-full mx-auto">
                            <div className="block w-full">
                                <input
                                    type="text"
                                    name="firstName"
                                    value={values?.firstName}
                                    placeholder="FIRST NAME"
                                    className="xl:text-[20px] md:text-[20px] text-[18px] placeholder-[#292929] w-full xl:min-h-[68px] lg:min-h-[50px] min-h-[40px] xl:mb-[32px] lg:mb-[25px] md:mb-[20px] mb-[15px] border-2 p-[10px_34px] text-[#292929] border-[#292929]"
                                    onChange={handleChange}
                                />
                                {errors?.firstName && (
                                    <p className="text-[12px] text-red-800 mt-1">
                                        {errors?.firstName}
                                    </p>
                                )}
                            </div>
                            <div className="block w-full">
                                <input
                                    type="text"
                                    name="lastName"
                                    value={values?.lastName}
                                    placeholder="LAST NAME"
                                    className="xl:text-[20px] md:text-[20px] text-[18px] placeholder-[#292929] w-full xl:min-h-[68px] lg:min-h-[50px] min-h-[40px] xl:mb-[32px] lg:mb-[25px] md:mb-[20px] mb-[15px] border-2 p-[10px_34px] text-[#292929] border-[#292929]"
                                    onChange={handleChange}
                                />
                                {errors?.lastName && (
                                    <p className="text-[12px] text-red-800 mt-1">
                                        {errors?.lastName}
                                    </p>
                                )}
                            </div>
                            <div className="block w-full">
                                <input
                                    type="text"
                                    name="email"
                                    value={values?.email}
                                    placeholder="EMAIL"
                                    className="xl:text-[20px] md:text-[20px] text-[18px] placeholder-[#292929] w-full xl:min-h-[68px] lg:min-h-[50px] min-h-[40px] xl:mb-[32px] lg:mb-[25px] md:mb-[20px] mb-[15px] border-2 p-[10px_34px] text-[#292929] border-[#292929]"
                                    onChange={handleChange}
                                />
                                {(emailExistError || errors?.email) && (
                                    <p className="text-[12px] text-red-800 mt-1">
                                        {emailExistError || errors?.email}
                                    </p>
                                )}
                            </div>
                            <div className="block w-full relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={values?.password}
                                    placeholder="PASSWORD"
                                    className="xl:text-[20px] md:text-[20px] text-[18px] placeholder-[#292929] w-full xl:min-h-[68px] lg:min-h-[50px] min-h-[40px] xl:mb-[32px] lg:mb-[25px] md:mb-[20px] mb-[15px] border-2 p-[10px_34px] text-[#292929] border-[#292929]"
                                    onChange={handleChange}
                                />
                                <span
                                    className="absolute transform -translate-y-1/2 right-5 cursor-pointer eye_icon xl:top-[34px] lg:top-[28px] top-[28px]"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? "👁️" : <VisibilityOff />}
                                </span>
                                {errors?.password && (
                                    <p className="text-[12px] text-red-800 mt-1">
                                        {errors?.password}
                                    </p>
                                )}
                            </div>
                            <div className="lg:text-right">
                                <button
                                    type="submit"
                                    className="transition duration-500 ease-in-out cursor-pointer bg-[#FBC60C] border-2 border-[#FBC60C] xl:min-h-[68px] lg:min-h-[50px] min-h-[40px] w-full xl:text-[25px] md:text-[20px] text-[18px] text-[#292929] font-normal hover:bg-white"
                                >
                                    {processLoader ? <MiniLoader /> : "GET STARTED"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {successMsg && <p className="text-sm text-green-800">{successMsg}</p>}
            </div>
        </>
    );
};
export default PowerlineForm;