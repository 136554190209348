import React, { useEffect } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import _ from 'lodash';
import OrderSummery from './OrderSummery';
import { CreateObject, OuterLoader } from '../../../services/Methods/checkoutPayloads';
// import PointCollection from '../../../Components/PointCollection';
import { DialogActions } from '@material-ui/core';
import { ConnectedFocusError } from 'focus-formik-error';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import AddressSection from './AddressChangeForm';
import { MainCheckOutStyle } from '../../../../styles/CheckoutSection.style';
import LoaderComponent from '../../../Inputs/LoaderComponent';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import withCheckoutHOC from '../../../services/HOC/withCheckoutHOC';
import { SHIPPING_VALIDATION_OBJECT, VALIDATION_SCHEMA, _formDefaultFormat } from '../../../services/Methods/validationSchema';
import ShippingSection from './ShippingSection';
import SavedCards from './SavedCards';
import PaymentSection from './PaymentSection';
import { Link } from 'react-router-dom';
import DialogComponent from '../../../Inputs/DailogComponent';
import OneTimePass from './OtpSection';
import { AddressSuggestionDialog } from '../../../services/Methods/commonPayload';
import { setErrorAddressData } from '../../../services/Redux/Reducer/CheckoutSlice';
import CouponComponent from './CouponComponent';
import AddressOptions from './AddressOptions';
import { useFormikContext } from 'formik';

const CheckoutSection = (props) => {

    const {
        activeStep, setActiveStep, countryState, setCountryState, formData, shippingMethods, selectedShippingOption,
        selectedPayment, setSelectedPayment, onSelectedCardError, calculatedData, savedCards, paymentOptions, paymentFormHtml,
        checkValidUser, userDetails, setUserDetails, savedThisCard, setSavedThisCard, otp, otpPopUp, setOtpPopUp, isUserLogin,
        navigate, ROUTING_TEXT, isLoading, shippingLoading, AllProductAndCheckoutText, useDefaultAddressOrNot, setUseDefaultAddressOrNot, userData,
        errorAddressData, couponCode, setCouponCode, couponErrorData, askPopupDlocal, paymentOption_ID, iframeLoader,
        sameAsBillingChecked, setSameAsBillingChecked,

        _handleCardChange, handleChangeFunction, _handleShippingChange, _onHandleSubmit, _createMarchentProfile, _authenticateOtp,
        error, countryCodes, normalAddressError, fetchUserDataLoading, handleChangeValidate, _onCouponClick, _setUseDefaultAddressFunction,
        setFormData, comingFrom, _handleDlocalPopup, _paymentOptionSetBilling, AUTOSHIP_PRODUCTS_ON_CART
    } = props;

    const PARENTS_PRODUCT_CALCULATE = _.filter(calculatedData?.oneTimeCalculateData?.details, (value) => value?.parentItemId === null);
    const PARENTS_PRODUCT_AUTOSHIP = _.filter(calculatedData?.autoshipCalculateData?.details, (value) => value?.parentItemId === null);

    const NORMAL_PRODUCTS = _.map(PARENTS_PRODUCT_CALCULATE, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v))
            , autoship: "oneTime"
        });
    });

    const AUTOSHIP_PRODUCTS = _.map(PARENTS_PRODUCT_AUTOSHIP, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v)),
            autoship: "autoship"
        })
    });

    const {
        shippingAddress_text, PaymentInfo_text, placeOrder_text, updateProduct_and_promotion, continueShopping_text,
        customerInformation_text, ShippingSection_TEXTS, SavedCards_TEXTS, same_as_shipping_text
    } = AllProductAndCheckoutText;

    let Status = false;
    const PAYMENT_OPTIONS = (AUTOSHIP_PRODUCTS_ON_CART?.length > 0) ? _.filter(paymentOptions, { recurringSupported: true }) : paymentOptions;

    const FocusError = () => {
        const { errors, isSubmitting, isValidating } = useFormikContext();

        useEffect(() => {
            if (isSubmitting && !isValidating) {
                let keys = Object.keys(errors);
                if (keys.length > 0) {
                    const selector = `[name=${keys[0]}]`;
                    const errorElement = document.querySelector(selector);
                    if (errorElement) {
                        errorElement.focus();
                    }
                }
            }
        }, [errors, isSubmitting, isValidating]);

        return null;
    };


    return (
        <>
            <MainCheckOutStyle>
                {(fetchUserDataLoading || isLoading) && <LoaderComponent />}
                <div className='checkout-main'>
                    <div className='container '>
                        <Formik
                            enableReinitialize
                            initialValues={{ ...formData, termscondition: false, sameShipping: false }}
                            validationSchema={SHIPPING_VALIDATION_OBJECT(null)}
                            onSubmit={(values, actions) => {
                                const { country, state } = countryState;
                                const currentValues = {
                                    phone: values?.shipping_phone,
                                    email: userDetails?.email || values?.shipping_email,
                                    firstName: userDetails?.firstName || values?.shipping_urname,
                                    lastName: userDetails?.lastName || values?.shipping_lastname,
                                    city: userDetails?.city || values?.shipping_city,
                                    zipcode: userDetails?.zipcode || values?.shipping_zipcode,
                                    address: userDetails?.address || values?.shipping_street,
                                    webAlias: userDetails?.webAlias,
                                    country: userDetails?.country || values?.shipping_country,
                                    state: userDetails?.state || values?.shipping_state,
                                    address_2: userDetails?.address_2 || values?.shipping_street2,
                                }
                                setUserDetails(currentValues);
                                // if (checkValidUser) {
                                _onHandleSubmit({ ...values, shipping_country: country, shipping_state: state });
                                // } else {
                                //     _createMarchentProfile(currentValues, () => {
                                //         _onHandleSubmit(values);
                                //     });
                                // }
                            }}
                        >
                            {(fieldValue) => {
                                return (
                                    <Form id="collect-form">
                                        <FocusError />
                                        <ConnectedFocusError />
                                        <div className='formAndOrderSummery'>
                                            <div className='shipping-form'>
                                                <h2>{customerInformation_text}</h2>
                                                <div className='shipping_fields'>
                                                    <h3 className='checkout_heading_address'>{shippingAddress_text}</h3>
                                                    {(isUserLogin && userData?.emailAddress && comingFrom === 'normal')
                                                        ?
                                                        <div className='mb-2 field-class'>
                                                            <AddressOptions {...{
                                                                checkoutError: error,
                                                                setDefaultValues: setUserDetails, _setUseDefaultAddressFunction,
                                                                sameAsBillingChecked, _paymentOptionSetBilling,
                                                                allUserData: userData, handleChangeFunction, userFormData: formData, VALIDATION_SCHEMA, setUserFormData: setFormData,
                                                                countryCodes, normalAddressError, countryState, setCountryState, NORMAL_PRODUCTS
                                                            }} />
                                                            <div className='text-[16px] text-red-500 font-semibold'>{normalAddressError}</div>
                                                        </div>
                                                        :
                                                        <>
                                                            <AddressSection section="shipping" {...{
                                                                countryCodes,
                                                                normalAddressError,
                                                                countryState,
                                                                setCountryState,
                                                                fieldValue,
                                                                handleChangeFunction
                                                            }} />
                                                            <div className='text-[16px] text-red-500 font-semibold'>{error}</div>
                                                        </>
                                                    }

                                                </div>
                                                <div className='shipping_section' style={{ display: "flex", gap: "30px" }}>
                                                    {_.map(['oneTime', 'autoship'], (data, i) => {
                                                        if (!Status) {
                                                            Status = (shippingMethods[`${data}ShipMethod`]?.length > 0)
                                                        }
                                                        return (
                                                            <React.Fragment key={data}>
                                                                {(shippingMethods[`${data}ShipMethod`]?.length > 0) &&

                                                                    <ShippingSection section={(data === "oneTime") ? "Today's order" : 'Autoship'}
                                                                        {...{
                                                                            selectedCheck: selectedShippingOption[`${data}SelectedShipping`],
                                                                            shippingMethods: shippingMethods[`${data}ShipMethod`],
                                                                            _handleShippingChange,
                                                                            ShippingSection_TEXTS
                                                                        }}
                                                                    />
                                                                }
                                                                {(!Status && i === 0) &&
                                                                    <div className='shipping_option_div'>
                                                                        <h3 style={{ textTransform: "capitalize" }} className='!mb-[10px]'>
                                                                            {ShippingSection_TEXTS?.shipMethod_text}
                                                                        </h3>
                                                                        {shippingLoading && <p className='error'>Please enter your shipping address to see the available shipping methods</p>}
                                                                        {(!shippingLoading) &&
                                                                            <p className='error text-red-500 mb-[10px]'>
                                                                                No shipping method available on selected address
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                                {/* {(calculatedData?.pointAccounts?.length > 0) &&
                                                                <PointCollection {...{ calculatedData, usePoint, setUsePoint, handleChangeFunction }} />
                                                            } */}
                                                {/* <CouponComponent {...{ couponCode, setCouponCode, _onCouponClick, couponErrorData }} /> */}

                                                <div>
                                                    <h3 className='checkout_heading_address'>{PaymentInfo_text}</h3>
                                                    {isUserLogin &&
                                                        <SavedCards {...{
                                                            setSameAsBillingChecked,
                                                            selectedPayment, setSelectedPayment, paymentOptions: PAYMENT_OPTIONS, _handleCardChange, savedCards, SavedCards_TEXTS
                                                        }} />
                                                    }
                                                    {(selectedPayment?.selectedOptionForPayment === 2) &&
                                                        <>
                                                            {(selectedPayment?.selectedPaymentDropdown === 1) &&
                                                                <div className="mt-4 flex gap-[20px] sameAsShipping-checkbox ">
                                                                    <input
                                                                        type="checkbox"
                                                                        className='w-[24px] h-[33px]'
                                                                        name="sameAsBilling"
                                                                        checked={sameAsBillingChecked}
                                                                        onChange={(e) => {
                                                                            if (e?.target?.checked) {
                                                                                setSameAsBillingChecked(e?.target?.checked);
                                                                                _paymentOptionSetBilling(formData);

                                                                            } else {
                                                                                setSameAsBillingChecked(e?.target?.checked);
                                                                                window.paymentOption?.clearBilling();
                                                                            }
                                                                        }} />
                                                                    {/* {(paymentOptions?.length > 0 && paymentOptions[0]?.paymentOptionTypeDescription !== 'Redirect') && */}
                                                                    <p className='m-0 text-[21px]'>{same_as_shipping_text}</p>
                                                                    {/* } */}
                                                                </div>
                                                            }
                                                            <PaymentSection {...{
                                                                selectedPayment,
                                                                minHeight: _.find(PAYMENT_OPTIONS, { paymentOptionId: selectedPayment?.selectedPaymentDropdown })?.paymentOptionTypeDescription === 'Redirect' ? "50px" : "550px",
                                                                iframeLoader, paymentFormHtml, savedThisCard, setSavedThisCard
                                                            }} />
                                                        </>
                                                    }
                                                </div>

                                            </div>

                                            <div className='mainorder_summery'>
                                                <div className="sticky-wrap">
                                                    {(NORMAL_PRODUCTS?.length > 0 && Status)
                                                        ?
                                                        <OrderSummery calculate={calculatedData?.oneTimeCalculateData}
                                                            cartData={NORMAL_PRODUCTS}
                                                            section="Today's"
                                                        />
                                                        :
                                                        <OuterLoader section="Normal" />
                                                    }
                                                    {(AUTOSHIP_PRODUCTS?.length > 0 || AUTOSHIP_PRODUCTS_ON_CART?.length > 0)
                                                        ?
                                                        <OrderSummery calculate={calculatedData?.autoshipCalculateData}
                                                            cartData={AUTOSHIP_PRODUCTS?.length > 0 ? AUTOSHIP_PRODUCTS : AUTOSHIP_PRODUCTS_ON_CART}
                                                            section="Autoship"
                                                        />
                                                        :
                                                        (NORMAL_PRODUCTS?.length > 0) ? <></> : <OuterLoader section="Autoship" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='policyCheckAndSubmit'>
                                            <>
                                                <div className="mb-4 condition-checkout flex gap-[20px]">
                                                    <input type="checkbox" className='w-[24px] h-[33px]'
                                                        checked={fieldValue?.values?.termscondition || false}
                                                        onChange={(e) => { fieldValue?.setFieldValue('termscondition', e.target.checked); }}
                                                        name="termscondition"
                                                    />
                                                    <p className='m-0 text-[21px]'>I have read and agree to the
                                                        <Link to={`/${storeAssigned()}/${ROUTING_TEXT?.terms_routingName}`} style={{ color: "rgb(72, 98, 241)" }} target="_blank" > website terms and conditions*</Link>
                                                    </p>
                                                </div>
                                                <ErrorMessage name='termscondition' render={(msg) => <p className=' text-red-500'>{msg}</p>} />
                                            </>
                                            <div className="mb-4 promotions-checkbox flex gap-[20px]">
                                                <input type="checkbox" className='w-[24px] h-[33px]' name="termscondition1" />
                                                <p className='m-0 text-[21px]'>{updateProduct_and_promotion}</p>
                                            </div>
                                            <div className='text-[16px] text-red-500 font-semibold'>{error}</div>
                                            <div className='place-order flex gap-[30px] mt-[40px]'>
                                                {(activeStep > 0)
                                                    ?
                                                    <ButtonComponent onClick={() => setActiveStep(activeStep - 1)}>Back</ButtonComponent>
                                                    :
                                                    <ButtonComponent onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.Shop_routingName}`)}>
                                                        {continueShopping_text}
                                                    </ButtonComponent>
                                                }
                                                <ButtonComponent type='submit' disabled={(NORMAL_PRODUCTS?.length === 0 && AUTOSHIP_PRODUCTS?.length === 0)}>
                                                    {placeOrder_text}
                                                </ButtonComponent>
                                            </div>
                                        </div>

                                        <DialogComponent opend={askPopupDlocal} handleClose={_handleDlocalPopup} classFor='askdlocal_confirmation'>
                                            <p>{paymentOption_ID?.title} is not available for SmartShip purchases.  Click OK to remove the SmartShip items from your shopping cart.</p>
                                            <DialogActions className='button_action'>
                                                <ButtonComponent classes="order_submit" onClick={() => _handleDlocalPopup('confirm', () => fieldValue?.handleSubmit())}>Confirm</ButtonComponent>
                                            </DialogActions>
                                        </DialogComponent>
                                        <DialogComponent opend={otpPopUp} handleClose={() => setOtpPopUp(false)} title="" classFor="otpSection_data">
                                            {/* <OTPCSS> */}
                                            <OneTimePass {...{ otp, handleChangeValidate }} />
                                            <DialogActions className='button_action'>
                                                <ButtonComponent classes="order_submit" onClick={() => { _authenticateOtp(() => fieldValue?.handleSubmit()) }}>
                                                    Submit
                                                </ButtonComponent>
                                            </DialogActions>
                                            {/* </OTPCSS> */}
                                        </DialogComponent>
                                    </Form>
                                )
                            }}
                        </Formik>
                        {localStorageCall().getSingleItem('error-payment') && <p className='error'>{localStorageCall().getSingleItem('error-payment')}</p>}
                    </div>
                </div>
            </MainCheckOutStyle>
            <AddressSuggestionDialog {...{
                errorAddressData, useDefaultAddressOrNot, closeFunction: () => props?.dispatch(setErrorAddressData({ objectData: null, section: "object" })),
                setUseDefaultAddressOrNot, userData
            }} />
        </>
    )
}
export default withCheckoutHOC(CheckoutSection);