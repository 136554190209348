import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { getCustomerRefferal } from '../../../services/Redux/Reducer/CustomerSlice';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { REFFERAL_VALUES, storeAssigned } from '../../../services/Methods/normalMethods';
import { useOutletContext } from 'react-router-dom';
import { ValidateCustomerProfile } from '../../../services/Redux/Reducer/LoginSlice';

const UseCreateAccountHook = (props) => {
    const { isUserLogin, UserCountry, storeAssignUppercase, userIpCountry, Localkey, setShowPopup, showPopup, storeAssign, setStoreAssign, LOCAL_STORAGE_KEY, selectedStore, setSelectedStore } = useOutletContext();
    const [openPopup, setOpenPopup] = useState(false);
    const [userField, setUserField] = useState();
    const [isLoadingTrue, setIsLoadingTrue] = useState(false);
    const [navigateLink, setNavigateLink] = useState('');
    const [isCustomerLoggedIn, setIsCustomerLoggedIn] = useState(false);
    const [webAliesError, setWebAliesErros] = useState({
        isWebAliesError: "",
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });
    const dispatch = useDispatch();
    const handleChange = (values) => {
        setUserField(values);
    }
    function _navigateFunction(link) {
        if (location?.pathname !== `/${storeAssigned()}/powerline`) {
            window.location.assign(`/${storeAssigned()}/${link}`)
        }
    }

    function _validateRefferalFunction() {
        setIsLoadingTrue(true);
        if (userField) {
            dispatch(getCustomerRefferal((data) => {
                setIsLoadingTrue(false);
                if (data?.length === 0) {
                    setWebAliesErros((prv) => ({ ...prv, isRefferalError: "Referral code is not found", isRefferalValid: false, isRefferalId: null }));
                    setTimeout(() => {
                        setWebAliesErros('');
                    }, 2000);
                } else {
                    setOpenPopup(false);
                    setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
                    const refferalData = JSON.stringify({ "id": data[0]?.customerId, "name": data[0]?.publicProfile?.fullName, "webalies": data[0]?.webAlias });
                    localStorageCall().setItem("refferal_link", refferalData);
                    _navigateFunction(navigateLink);
                }
            }, userField));
        }
    };

    function _commonCondtion(CUSTOMER_TYPE, data, section, link) {
        if (CUSTOMER_TYPE?.role === section) {
            if (data === 'VALID') {
                setIsCustomerLoggedIn(true);
            } else {
                _navigateFunction(link);
            }
        } else {
            if (isUserLogin) {
                _navigateFunction(link);
            } else {
                !REFFERAL_VALUES?.webalies ? setOpenPopup(true) : _navigateFunction(link);
            }
        }
    }

    function _popupConditionCheck(link, data) {
        const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
        if (link === "retail_signup") {
            if (['preferred', 'IBO']?.includes(CUSTOMER_TYPE?.role)) {
                setIsCustomerLoggedIn(true);
                return;
            }
            _commonCondtion(CUSTOMER_TYPE, data, 'retail', link);
        }
        else if (link === "preferred_customer") {
            if (['IBO']?.includes(CUSTOMER_TYPE?.role)) {
                setIsCustomerLoggedIn(true);
                return;
            }
            _commonCondtion(CUSTOMER_TYPE, data, 'preferred', link);
        }
        else if (link === "business_owner") {
            _commonCondtion(CUSTOMER_TYPE, data, 'IBO', link);
        }
    }

    function _checkingCustomerType(link) {
        setNavigateLink(link);
        dispatch(ValidateCustomerProfile(isUserLogin, (data) => {
            _popupConditionCheck(link, data)
        }))

    }

    return ({
        openPopup, setOpenPopup, userField, setUserField, webAliesError, setWebAliesErros, handleChange, _validateRefferalFunction, isLoadingTrue,
        setNavigateLink, navigateLink, _navigateFunction, _checkingCustomerType, isCustomerLoggedIn, setIsCustomerLoggedIn, isUserLogin, UserCountry, storeAssignUppercase, userIpCountry, Localkey, setShowPopup, showPopup, storeAssign, setStoreAssign, LOCAL_STORAGE_KEY, selectedStore, setSelectedStore
    })
}

export default UseCreateAccountHook;