import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import DialogComponent from '../../../Inputs/DailogComponent';
import TextFieldComponent from '../../../Inputs/TextFieldComponent';
import { REFFERAL_VALUES } from '../../../services/Methods/normalMethods';
import LoaderComponent from '../../../Inputs/LoaderComponent';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import localStorageCall from '../../../services/Methods/localstorage.hook';


const MainCreateAccountComponent = (props) => {
    const { openPopup, setOpenPopup, isLoadingTrue, webAliesError, handleChange, _validateRefferalFunction, _checkingCustomerType, SIGNUP_TEXT, _navigateFunction, navigateLink, UserCountry, storeAssignUppercase, userIpCountry, Localkey, setShowPopup, showPopup, storeAssign, setStoreAssign, LOCAL_STORAGE_KEY, selectedStore, setSelectedStore, isUserLogin, location } = props;
    const inputRef = useRef(null);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const handleInput = () => {
        const inputValue = inputRef.current.value;
        const isInputValid = inputValue.length > 0;
        setButtonDisabled(!isInputValid);
    };
    const WHOLSALE_SPAN = `<span className='text-white mt-2 text-[1.5rem]'>(${SIGNUP_TEXT?.wholesale_text})</span>`

    const CARDS_VALUES = [
        { id: 1, heading: SIGNUP_TEXT?.retialCustomer_text, discription: SIGNUP_TEXT?.retailDescription, link: `retail_signup` },
        { id: 2, heading: `${SIGNUP_TEXT?.preferredCustomer_text} ${WHOLSALE_SPAN}`, discription: SIGNUP_TEXT?.preferredDescription, link: `preferred_customer` },
        { id: 3, heading: `${SIGNUP_TEXT?.independentOwner_text} ${WHOLSALE_SPAN}`, discription: SIGNUP_TEXT?.independentDescription, link: `business_owner` },
    ];

    useEffect(() => {
        if (storeAssignUppercase && (storeAssignUppercase !== userIpCountry) && (UserCountry !== storeAssignUppercase) && !Localkey) {
            setShowPopup(true);
        }
    }, [UserCountry])

    const handleClose = () => {
        localStorageCall().setItem(LOCAL_STORAGE_KEY, 'true');
        setShowPopup(false);
    };

    return (
        <>
            <div>
                {showPopup && userIpCountry !== storeAssignUppercase && UserCountry !== storeAssignUppercase && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
                            {
                                isUserLogin && (
                                    <p className="text-gray-800 text-center mb-4">
                                        The Country on your account ({UserCountry}) does not match the store you are shopping ({storeAssign}).
                                    </p>
                                )
                            }
                            {
                                !isUserLogin && (
                                    <p className="text-gray-800 text-center mb-4">
                                        Your IP ({userIpCountry}) does not match the store you are shopping ({storeAssignUppercase}).
                                    </p>
                                )
                            }


                            <p className="text-gray-800 text-center mb-4">
                                Do you still want to continue ?
                            </p>
                            <div className="flex justify-center">
                                <button
                                    style={{
                                        padding: '0.5rem 1rem',
                                        // backgroundColor: '#8dfa00',
                                        color: 'white',
                                        borderRadius: '0.5rem',
                                        // transition: 'background-color 0.2s ease',
                                    }}
                                    onClick={() => handleClose()}
                                    className="px-4 py-2 text-white rounded-lg border-[#8dfa00] bg-[#8dfa00]"
                                >
                                    Continue
                                </button>
                            </div>
                            <div className="text-base/7 font-medium text-[#591069] mb-2 text-center">
                                OR
                            </div>

                            <div>
                                <ButtonComponent onClick={() => {
                                    _.map(['normal', 'distributor'], (row) => {
                                        setSelectedStore((prv) => ({ ...prv, defaultCode: prv?.newCode }))
                                        localStorageCall().setItem(LOCAL_STORAGE_KEY, 'true')
                                        if (_.includes(location?.pathname, 'main-enrollment')) {
                                            if (UserCountry !== userIpCountry) {
                                                window.location.assign(`/${userIpCountry.toLowerCase()}/main-enrollment`)

                                            } else {

                                                window.location.assign(`/${UserCountry.toLowerCase()}/main-enrollment`)

                                            }
                                        } else {
                                            if (UserCountry !== userIpCountry) {
                                                window.location.assign(`/${userIpCountry.toLowerCase()}`)
                                            } else {
                                                window.location.assign(`/${UserCountry.toLowerCase()}`)


                                            }
                                        }
                                    })
                                }}>
                                    Change {storeAssign} <ArrowRightAltIcon />  {isUserLogin ? UserCountry : userIpCountry}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="main-wrapper">
                <h2 className="text-center mt-[1.5rem] text-[32px] font-normal text-[#373f50] mb-3">{SIGNUP_TEXT?.readyTo_checkUp}</h2>
                <h4 className="font-normal text-[1.5rem] text-center leading-tight text-[#373f50] mb-3">{SIGNUP_TEXT?.selectHow_ToJoin}</h4>
                <div className="mx-auto lg:max-w-full  my-0 py-[50px]">
                    <div className="xl:gap-[30px] lg:gap-[20px] md:gap-[30px]  flex justify-center flex-wrap w-full">
                        {_.map(CARDS_VALUES, ({ id, heading, discription, link }) => (
                            <div className="h-[400px] xl:max-w-[390px] lg:max-w-[320px] md:max-w-[50%] relative bg-[#E9E7E7]" key={id}>
                                <h2 className="text-center text-white mb-2 h-[175px] bg-[#0071BA] flex flex-col text-[31px] px-[10px] py-[20px] justify-center 
                            align-center leading-[1.2]
                            ">{parse(heading) || heading}</h2>
                                <div className="offer-card-body text-[20px] px-[30px] pb-[10px]">
                                    <p className='text-[#373f50] font-light m-0 mb-[15px]'>{discription}</p>
                                </div>
                                <button onClick={() => _checkingCustomerType(link)} className="absolute bottom-[30px] py-[15px] xl:px-[30px] lg:px-[10px] w-full max-w-half bg-[#DEAA17] text-white rounded-[30px] right-0 left-0 mx-auto my-0"
                                >{SIGNUP_TEXT?.createAccount}</button>

                            </div>
                        ))}
                    </div>
                </div>

                {!REFFERAL_VALUES?.webalies &&
                    <DialogComponent opend={openPopup} handleClose={() => setOpenPopup(false)} title="Who referred you?" classFor="ReferralUser" >
                        <div className='container'>
                            <div className='row' style={{ textAlign: "center" }}>
                                <p style={{ fontSize: "21px" }}>Please provide the referral code from the person who introduced you to Provizion Global</p>
                            </div>
                            {isLoadingTrue ? <LoaderComponent /> : <div className='invitation-code'>
                                <TextFieldComponent type="text" placeholder='Invitation Code (xxxxxx)'
                                    onChange={(e) => {
                                        handleChange(e.target.value);
                                        handleInput();
                                    }}
                                    error={!!webAliesError?.isRefferalError}
                                    errorMessage={webAliesError?.isRefferalError}
                                    inputRef={inputRef}
                                />
                            </div>
                            }
                        </div>
                        <div className='blank-section'></div>
                        {/* <button
                    className="text-base font-normal inline-block text-button-color transition-all duration-300 ease-in-out hover:text-button-hover-color mt-7"
                    onClick={() => {
                        setOpenPopup(false);
                        _navigateFunction(navigateLink);
                    }}
                >
                    I don't have any referral
                </button> */}
                        <button type="Submit" disabled={isLoadingTrue || isButtonDisabled} className={`invite-referrer ${isLoadingTrue || isButtonDisabled ? 'disabled' : ''}`} onClick={() => _validateRefferalFunction()}>Ok</button>
                    </DialogComponent>}
            </div >
        </>

    )
};

export default MainCreateAccountComponent;
