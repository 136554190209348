import _ from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { storeAssigned } from "../services/Methods/normalMethods";
import Footer from "./Footer";
import Header from "./Header";

const LayoutComponent = ({ children, ...props }) => {
    const [showCountries, setShowCountries] = useState(false);
    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    };
    const path = usePathname();
    return (
        <React.Fragment>
            {(path === `/${storeAssigned()}` && import.meta.env.VITE_IS_COMING_SOON === 'true' || path === `/${storeAssigned()}/powerline`) ? null :
                <Header {...{ showCountries, setShowCountries, ...props }} />}
            {children}
            {(path === `/${storeAssigned()}` && import.meta.env.VITE_IS_COMING_SOON === 'true' || path === `/${storeAssigned()}/powerline`) ? null : <Footer {...props} />}
        </React.Fragment>
    )

}

export default LayoutComponent;